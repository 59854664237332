.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.css-tq0shg {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.css-jmskxt {
  /*padding-left: 26px;*/
  padding-right: 26px;
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #1e1e1e;
  border-bottom: 1px solid #161a20;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 64px;
  z-index: 888;
  position: fixed;
  top: 0;
  left: 0;
}

.css-jmskxt > div {
  height: 100%;
}

.css-1mvf8us {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  color: #eaecef;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-left: 8px;
  margin-right: 8px;
  height: 100%;
  color: #00b897;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.css-1jgk2rg {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #00b897;
  width: 24px;
  height: 24px;
  font-size: 24px;
  fill: #eaecef;
  fill: #00b897;
  width: 64px;
  height: 24px;

  &.css-logo {
    height: 35px;
    width: 35px;
    border-radius: 5px;
    margin-left: 10px;
  }
}

.css-jmskxt a,
.css-jmskxt a:active,
.css-jmskxt a:visited {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.css-1tp5kus {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #eaecef;
  overflow: hidden;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: auto;
  visibility: visible;
  margin-left: 25px;
}

.css-1tp5kus > div,
.css-1tp5kus > a {
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  height: 100%;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.css-1smf7ma {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  color: #fff;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-left: 8px;
  margin-right: 8px;
  height: 100%;

  svg {
    font-size: 16px;
    color: #848e9c;
    margin-left: 2px;
  }
}

a {
  background-color: transparent;
}

.css-1smf7ma.active,
.css-1smf7ma:hover {
  color: #00b897;
}

.css-wu6zme {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.css-11y6cix {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.css-mu7imd {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 100%;
}

.css-15owl46 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: relative;
}

.css-vurnku {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
}

.css-1ql2hru {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.css-1x1srvk {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #00b897;
  width: 18px;
  height: 18px;
  font-size: 18px;
  fill: #eaecef;
  fill: #00b897;
  color: #848e9c;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-transform: rotateX(0);
  -ms-transform: rotateX(0);
  transform: rotateX(0);
}

.css-1rch7es {
  box-sizing: border-box;
  margin: 0px;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  background-color: rgb(246, 70, 93);
  font-size: 12px;
  color: rgb(255, 255, 255);
  height: 15px;
  min-width: 15px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 99999px;
  top: 0px;
  right: 8px;
  z-index: 9;
  position: absolute;
  transform: translate(50%);
}

.css-6px2js {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #00b897;
  width: 24px;
  height: 24px;
  font-size: 24px;
  fill: #eaecef;
  fill: #00b897;
}

.css-gv1gi9 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: none;
  pointer-events: none;
  visibility: hidden;
  z-index: 1100;
}

.css-1ox6s0y {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  background-color: #1e2329;
  position: relative;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  min-width: 200px;
  max-width: 400px;
  border-radius: 0 0 8px 8px;
}

.css-1xbszl8 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  color: rgb(234, 236, 239);
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  padding: 16px;
}

.css-165cxtr {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: rgb(132, 142, 156);
}

.css-9pwsq {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 12px;
}

.css-1xbszl8 .menu-ctx {
  font-size: 14px;
  margin-left: 12px;
}

.css-10nf7hq {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-1iqe90x {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #eaecef;
}

.order_navlist {
  position: fixed;
  /*inset: 0px auto auto 50px;*/
  /*transform: translate(1031px, 64px);*/
  /*-ms-transform: translate(1031px, 64px);*/
  /*-moz-transform: translate(1031px, 64px);*/
  /*-webkit-transform:translate(1031px, 64px);*/
  /*-o-transform:translate(1031px, 64px);*/
  min-height: 220px;
  min-width: 160px;
  background: #181a20;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 999999;
}

.optionli {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: left;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    width: 100%;
  }
}

.float-right {
  float: right;
}

.css-4qtnb6 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  background-color: #181a20;
  padding-top: 24px;

  width: 100vw;

  .wrapper {
    width: 100%;
    height: 150px;
    background: #181a20;
    padding: 10px 48px;

    .col1 {
      width: 100%;
      height: 60px;

      .logo {
        min-width: 100px;
        height: 60px;
        line-height: 60px;
        margin-right: 15px;
        float: left;

        span {
          color: #fff;
          font-size: 36px;
          font-weight: 600;
        }
      }
    }

    .menu-item {
      min-width: 100px;
      height: 60px;
      line-height: 60px;
      margin-right: 15px;
      float: right;

      a {
        color: #848e9c;
        font-size: 18px;
      }
    }

    .copying {
      width: 100%;
      height: 60px;
      line-height: 90px;
      text-align: center;
      border-top: 1px solid #848e9c;

      span {
        color: #848e9c;
        font-size: 20px;
      }
    }
  }
}

.fcfs {
  color: #73797f !important;
}

.fcgs {
  color: #00a4d8 !important;
}

.fccs {
  color: #73797f !important;
}

.fcc {
  color: #3d3d3d;
}

.fch {
  color: #000 !important;
}

.fcf {
  color: #fff !important;
}

.fck {
  color: #c7cce6 !important;
}

.fcy {
  color: #00b897;
}

.fcb {
  color: #0052fe !important;
}

.fred {
  color: #cf304a;
}

.fgreen {
  color: #03a66d;
}

.flogin {
  color: #6d7c82;
}

.floginbr {
  color: #16b979;
}

.fbaseblue {
  color: #0052fe;
}

.f12 {
  font-size: 12px;
}

.f14 {
  font-size: 14px !important;
}

.f16 {
  font-size: 16px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.f22 {
  font-size: 22px;
}

.f36 {
  font-size: 36px;
}

.famy {
  font-family: DINNext, BinancePlex, Arial, PingFangSC-Regular, Microsoft YaHei,
    sans-serif;
}

.fw {
  font-weight: bold;
}

.fl {
  float: left;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.tcbh {
  color: #707a8a !important;
}

.tcl {
  text-align: left;
}

.tcc {
  text-align: center;
}

.tcr {
  text-align: right;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #424852;
  font-size: 14px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #424852;
  font-size: 14px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #424852;
  font-size: 14px;
}

.fgreen {
  color: #0ecb81;
}

.fred {
  color: #f5465c;
}

.index-box-2 {
  border: none !important;
}

.index-box-3 {
  border: none !important;
  min-height: 300px !important;
}

.css-dlistbox-top {
  width: 100%;
  min-height: 200px;
  text-align: center;
}

.ss-dlistbox-top-text {
  padding-top: 50px;
}

.css-dlistbox-l {
  float: left;
  width: 40%;
  min-height: 600px;
}

.css-dlistbox-r {
  float: right;
  width: 60%;
  min-height: 600px;
  position: relative;
}

.ss-dlistbox-top-p1 {
  font-size: 30px;
  line-height: 36px;
  color: #293c57;
  margin-bottom: 8px;
  font-weight: 700;
}

.ss-dlistbox-top-p2 {
  font-size: 15px;
  line-height: 22px;
  color: #7f8fa4;
}

.icon-73beb614 {
  background-position: top;
  width: 38px;
  height: 38px;
  float: left;
  margin-top: 5px;
}

.icon1-73beb614 {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTIyLjE5NCA4Yy0uMTUyLS4yNjMtMS43LS43NjQtNC42NDUtMS41TDMwLjk2OSAwIDMyIDE0Yy0yLjM4OS0xLjY2Ny0zLjc2Ni0yLjUtNC4xMjgtMi41QzI0Ljc0NSAxNy43NjMgMTIuMzg2IDI3LjUgMCAzMWMxMS4wNzMtNy40NTcgMTguNDctMTUuMTI0IDIyLjE5NC0yM3oiIGZpbGw9IiMyNDgzRkYiLz48cmVjdCBmaWxsPSIjMkVFMEI1IiB4PSIyOCIgeT0iMTYiIHdpZHRoPSI0IiBoZWlnaHQ9IjIwIiByeD0iMSIvPjxyZWN0IGZpbGw9IiMyRUUwQjUiIHg9IjIxIiB5PSIyMiIgd2lkdGg9IjQiIGhlaWdodD0iMTQiIHJ4PSIxIi8+PHJlY3QgZmlsbD0iIzJFRTBCNSIgeD0iMTQiIHk9IjI3IiB3aWR0aD0iNCIgaGVpZ2h0PSI5IiByeD0iMSIvPjxyZWN0IGZpbGw9IiMyRUUwQjUiIHg9IjciIHk9IjMwIiB3aWR0aD0iNCIgaGVpZ2h0PSI2IiByeD0iMSIvPjxyZWN0IGZpbGw9IiMyRUUwQjUiIHk9IjMzIiB3aWR0aD0iNCIgaGVpZ2h0PSIzIiByeD0iMSIvPjwvZz48L3N2Zz4=)
    no-repeat;
}

.icon2-73beb614 {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTM0IDEzSDVWOWExIDEgMCAwMTEtMWgyN2ExIDEgMCAwMTEgMXY0em0wIDQuMjIyVjI2YTEgMSAwIDAxLTEgMUg2YTEgMSAwIDAxLTEtMXYtOC43NzhoMjl6IiBmaWxsPSIjMjZFMEIzIi8+PHBhdGggZD0iTTEgMGgyN2ExIDEgMCAwMTEgMXYxNmExIDEgMCAwMS0xIDFIMWExIDEgMCAwMS0xLTFWMWExIDEgMCAwMTEtMXptMyAzYTEgMSAwIDAwLTEgMXYyYTEgMSAwIDAwMSAxaDRhMSAxIDAgMDAxLTFWNGExIDEgMCAwMC0xLTFINHoiIGZpbGw9IiMxNDcyRjciLz48L2c+PC9zdmc+)
    no-repeat;
}

.icon3-73beb614 {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTI0LjE4NyAxNC41MzJWOS41MDhjMC0yLjgyLTIuMjYyLTUuMDM1LTUuMTQxLTUuMDM1LTIuODggMC01LjE0MiAyLjIxNi01LjE0MiA1LjAzNXY1LjAyNGgtMy4yNlY5LjU5NmMwLTQuNjA3IDMuNjk3LTguMjI3IDguNDAyLTguMjI3IDQuNzA0IDAgOC40MDEgMy42MiA4LjQwMSA4LjIyN3Y0LjkzNmgtMy4yNnoiIGZpbGw9IiMyNDgzRkYiLz48cGF0aCBkPSJNMTkuMDk3IDQuNDc0VjEuMzY5YzQuNjguMDI2IDguMzUgMy42MzcgOC4zNSA4LjIyN3Y0LjkzNmgtMy4yNlY5LjUwOGMwLTIuODAyLTIuMjM1LTUuMDA4LTUuMDktNS4wMzR6IiBmaWxsPSIjMkVFMEI1Ii8+PHBhdGggZD0iTTE4IDI2LjgzVjMxYTEgMSAwIDAwMSAxdjVINVYxNGgxNHY3YTMgMyAwIDAwLTEgNS44M3oiIGZpbGw9IiMyNDgzRkYiLz48cGF0aCBkPSJNMjAgMjYuODNBMy4wMDEgMy4wMDEgMCAwMDE5IDIxdi03aDE0djIzSDE5di01YTEgMSAwIDAwMS0xdi00LjE3eiIgZmlsbD0iIzJFRTBCNSIvPjwvZz48L3N2Zz4=)
    no-repeat;
}

.css-dlistbox-l-content {
  height: 90px;
}

.css-dlistbox-l-content-4 {
  height: 130px !important;
}

.css-dlistbox-l-item1 {
  padding: 20px 20px 20px 20px;
}

.data-p-content {
  margin: 0px !important;
  padding: 0px !important;
  padding-left: 38px !important;
}

.data-p-title {
  margin: 0px;
  margin-top: 2px;
}

.ios-down-73beb614 {
  width: 134px;
  background: url(../icons/ios_down.svg) no-repeat;
  background-position: 50%;
  height: 44px;
  border-radius: 5px;
  transform: translateY(0);
  transition: transform 0.3s;
  float: left;
  margin-right: 5px;
}

.android-down-73beb614 {
  width: 150px;
  background: url(../icons/android_down.svg) no-repeat;
  background-position: 50%;
  height: 44px;
  border-radius: 5px;
  transform: translateY(0);
  transition: transform 0.3s;
  float: left;
}

.css-dlistbox-bg {
  width: 700px;
  height: 520px;
  background: url(../img/icon/advantage_bg.png) no-repeat;
  background-size: 100%;
  margin-left: 6px;
}

.css-dlistbox-phone {
  width: 252px;
  height: 515px;
  background: url(../img/icon/advantage_phone.png) no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: -2px;
}

.img1 {
  background: url(../img/icon/advantage_phone_ui1.png) no-repeat;
  background-size: 100%;
  top: 55px;
  display: block;
  width: 310px;
  height: 192px;
  position: absolute;
  left: -40px;
  transform: scaleX(1);
  transition: all 0.8s 1s;
}

.img2 {
  height: 202px;
  background: url(../img/icon/advantage_phone_ui2.png) no-repeat;
  background-size: 100%;
  top: 255px;
  display: block;
  width: 310px;
  position: absolute;
  left: -40px;
}

.css-dlistbox-desc-box {
  float: left;
}

.css-dlistbox-sub-desc {
  min-height: 190px;
}

.css-dlistbox-top-desc {
  width: 100%;
  text-align: center;
}

.home_concave__QkT3C .home_infoWrapper__G_KFW p {
  margin-top: 30px;
  text-align: center;
  font-weight: 700;
}

.ss-dlistbox-top-p2 {
  height: 33px;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  color: #5a5e62;
  margin: 8px 0;
}

.footer-box {
  width: 100%;
  background: red;
  min-height: 500px;
}

.home_mainMedia__fc9Ke {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 180px;
  background: #fff;
  border: 1px solid #16b979;
  font-size: 20px;
  color: #484860;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 36px;
}

.index-box-4 {
  height: 100%;
  width: 100%;
  margin: 0px;
}

.css-dlistbox-4 {
  padding: 96px 0px;
  min-height: 400px !important;
}

.css-1hc8c4h-box-4 {
  border: none !important;
  background-image: url(../img/icon/community_bg.png);
  background-size: cover;
  background-position: 100%;
  height: 100%;
  width: 100%;
}

.svg_list {
  margin-top: 20px;
}

.footer_svg {
  margin-right: 10px;
  display: inline-block;
}

.pop-box {
}

.pop-content {
  background: #fff;
}

.pop-content-desc {
  padding: 20px;
}

.footer-box-span {
  color: #848e9c;
  text-decoration: none !important;
}

.layui-layer-title {
  height: 60px !important;
  line-height: 60px !important;
  background: #dddddd !important;
  color: #555555 !important;
  font-size: 28px !important;
}

.cion_logo {
  width: 24px;
  margin-right: 10px;
}

.market-div {
  transition: all 0.3s;
}

.login-reg {
  margin-top: 10px;
}

.login-title {
  margin-top: 10px;
}

.margin-topbox-px-10 {
  margin-top: 10px;
}

.ks_buy {
  padding: 10px 25px;
  margin: 3px 20px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.ks_buy_up {
  background: #0ecb81;
}

.ks_buy_down {
  background: #f5465c;
}

.order-top {
  width: 100%;
  height: 40px;
  background: #1a1b1c !important;
}

.order-main {
  line-height: 30px;
}

.order-main-table {
  line-height: 20px;
  background: #1a1b1c;
}

.order-top-span {
  line-height: 30px;
  float: left;
  height: 40px;
  padding: 5px 20px;
  cursor: pointer;
}

.order-top-select {
  background: #2c2d2e;
  border-top: 2px solid #2c2d2e;
  color: #3db485 !important;
  font-weight: 700;
}

.order-main-table-history {
  display: none;
}

.refresh-icon {
  line-height: 35px;
  float: right;
  margin-right: 30px;
}

.usdt-shadow {
  border-bottom: 1px solid #2c2d2e;
  margin-top: 10px;
}

.klinetitle-s-box {
  float: left;
}

.btn_bg_color {
  background: rgba(17, 193, 164, 0.1);
  border-radius: 4px;
  color: #fbbc01;
}

.btn_bg_color_tch {
  color: #73797f !important;
}

.btn_bg_color_tcg {
  color: #3db485 !important;
}

.klinetitle-s-box-l {
  border-right: 2px dashed #2c2d2e;
}

.input-desc {
  width: 30%;
  line-height: 36px;
  /*margin: 0px 5px 0px 10px;*/
}

.table-history-more {
  min-height: 160px;
  width: 200vh;
  display: table-cell;
  /*ä¸»è¦æ˜¯è¿™ä¸ªå±žæ€§*/
  vertical-align: middle !important;
  text-align: center !important;
  margin: 0 auto !important;
}

.empty-svg {
  margin-top: 10px;
  width: 150px;
}

.header-title {
  margin-left: 0px;
  line-height: 64px;
}

.buy_navlist {
  top: 48px;
  left: 260px;
  min-height: 160px;
}

.buy-sub {
  width: 0px;
}

.buy_coins:hover .buy-sub {
  display: block !important;
}

.buy_navlist:hover {
  display: block !important;
}

.trad_coins:hover .trad-sub {
  display: block !important;
}

.trad_navlist {
  top: 48px;
  left: 340px;
  min-height: 160px;
}

.trad_navlist:hover {
  display: block !important;
}

.li-sub:hover {
  background: #00b897;
}

.li-sub {
  border-radius: 5px;
}

.css-8hstpq-bg {
  background: url("../img/banner/bg-home.jpg") 50% center / 100% 100% no-repeat
    rgb(255, 255, 255);
  min-height: 763px;
  z-index: -1;
}

.hot-2 {
  float: left;
}

.table-box {
  background: #151617;
}

.sjxjinput::-webkit-input-placeholder {
  color: #73797f;
}

.css-1wr4jig {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.css-1pysja1 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.css-6nqu2s {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
}

.css-b22026 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
}

.css-1xamyaw {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /*margin-left: auto;*/
  margin-right: auto;
  max-width: 1200px;
  font-size: 12px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-1xamyaw {
  margin-bottom: 0;
  padding-left: 24px;
  padding-right: 24px;
}

.css-o32gok {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  font-size: 24px;
  /*color: #1E2329;*/
  color: #fafafa;
}

.css-jwys36 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: none;
  margin-left: auto;
}

.css-8puh95 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  height: 32px;
  margin-top: 4px;
  margin-bottom: 0px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1.6;
  border: 1px solid transparent;
  border-color: #eaecef;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 0;
  width: 100%;
  height: 44px;
}

.css-1t9l9dt {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: block;
  position: relative;
  &::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.438);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.css-8hstpq {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 0;
  margin-left: auto;
}

.tophangqi {
  height: 120px;
  width: 22%;
  border-radius: 10px;
  padding: 0px 15px;
}

.css-194m5n4 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  /*padding-bottom: 24px;*/
  border-radius: 0px;
  background-color: #191919;
}

.css-1hc8c4h {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 32px 32px 0px 0px;
}

.css-1hc8c4h {
  padding-left: 8px;
  padding-right: 8px;
}

.css-dlistbox {
  width: 100%;
  min-height: 500px;
}

.css-dlistbox .name-coin {
  display: flex;
  align-items: center;
  gap: 5px;
}

.table-scroll {
  max-height: 550px;
  overflow: hidden;
  overflow-y: auto;
}

.market-div {
  transform: scale(1);
  background: rgb(255, 255, 255);

  &:hover {
    transform: scale(1.02);
    background: rgb(243, 243, 243);
  }
}

.css-dlbbox {
  width: 100%;
  height: 40px;
  background-color: #1f1f1f;
  color: #d5d5d6;
  padding: 0px 0px;
  > div {
    line-height: 40px;
  }
}

.listtitle {
  height: 30px;
  line-height: 30px;
}

.css-dlbbox2 {
  width: 100%;
  height: 50px;
  padding: 0px 10px;
  border-bottom: 1px solid #272727;
  > div {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.listtitle2 {
  height: 50px;
  line-height: 50px;
}

.bg-box {
  background: #fff;
  width: 100%;
  position: relative;
  height: 763px;
  padding-top: 0;
  text-align: center;
}

.css-8hstpq_img {
  width: 100%;
  height: 120px;
}

.tophangqi {
  /*margin-right: 1%;*/
  width: 25%;
}

.reginput {
  width: 380px !important;
  height: 60px !important;
  border-radius: 10px !important;
}

.regbtn {
  width: 100px !important;
  height: 60px !important;
  background: #fbbc01 !important;
  border-radius: 10px !important;
  border: none;
  color: #fff;
  font-size: 18px;
}

.css-1hc8c4h {
  border-radius: 0px;
  padding: 0px;
}

.regbtnimg {
  width: 720px;
  position: absolute;
  right: 0px;
  bottom: -120px;
  box-shadow: none !important;
}

.css-1xamyaw-content {
  width: 100%;
  color: #fff;
  padding-left: 5%;
  max-width: 700px;
  text-align: left;
}

.reg-input-box {
  margin-top: 60px;
  padding: 10%;
  z-index: 999;
}

.option-box {
  width: 40%;
  height: 60%;
  background: #07c160;
  margin-top: 7%;
  margin: 7% auto;
  line-height: 30px;
  border-radius: 3px;
  text-decoration: none !important;
}

.profit_loss_g {
  padding: 7px;
}

.profit_loss_r {
  padding: 7px;
}

.fred {
  color: #fa5252;
}

.rgreen {
  color: #12b886;
}

.img-fluid {
  width: 288px !important;
  height: 122px !important;
  border-radius: 5px;
}

.notice {
  height: 50px;
  width: 100%;
}

.box {
  height: 50px;
  background-color: transparent;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /*margin: auto;*/
  overflow: hidden;
  color: #000;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid rgb(48, 60, 76);
  padding: 0px 120px;
}

.box ul {
  position: absolute;
  top: 0;
  left: 0;
}

.box ul li {
  line-height: 50px;
  list-style: none;
  padding: 0 30px;
  box-sizing: border-box;
  cursor: pointer;
}

.box_ul > li {
  padding-left: 0px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #7b8ab8;
  background-color: #f0f5fa;
  background-clip: padding-box;
  border: 0 solid #bed1e6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  box-shadow: inset 2px 2px 8px rgba(55, 94, 148, 0.3),
    inset -3px -2px 5px rgba(255, 255, 255, 0.8);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (min-width: 576px) {
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}

.marquee-text,
.top {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: flex-start;
  animation: moveX 15s 0.5s linear infinite;
  animation-fill-mode: forwards;
}

.d-inline-flex {
  display: inline-flex;
}

.align-items-start {
  align-items: flex-start;
}

.box_top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box_top .item-top {
  font-size: 16px;
  padding: 4px 7px;
  border-radius: 4px;
  flex: none;
}

.box_top .item-top:not(:last-child) {
  margin-right: 20px;
}

.top a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: #ffffff;
  text-align: left;
  margin-right: 0.5rem;
  font-size: 14px;
  display: inline-block;
}

.notice-btn {
  width: 120px;
  margin-left: 40px;
  /* margin-right: 20px; */
  height: 50px;
  background: #0052fe !important;
  float: right;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  color: #fff;
  border-radius: 10px 0px 0px 10px;
}

@keyframes moveX {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.news__slider {
  display: flex;
  gap: 5px;
  margin-top: 1.5rem;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;

  .item_news {
    width: 25%;
  }
}

@media screen and (max-width: 568px) {
  .news__slider {
    .item_news {
      width: 50%;
      flex: 1 1 auto;
    }
  }

  html .tradingview-widget-container {
    &.header-trading {
      height: 50px;

      iframe {
        height: 50px;
      }
    }
  }
}

.col-2 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.666667%;
}
.col-3 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.333333%;
}

.home_infoWrapper__G_KFW {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 18px;
    color: #7b8ab8;
  }
}

body {
  background: #fff;
  color: rgb(0, 0, 0);
  font-family: sans-serif;
  overflow: hidden;
}

div.starfield {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url(../img/banner/bg-home.jpg);
  background-size: cover;
}

div.starfield .static {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  transform-origin: -31vw -38vh;
  box-shadow: 36vw -46vh 1px 0.75px rgba(255, 244, 245, 0.5235723442),
    -68vw -54vh 1px 0.75px rgba(252, 243, 255, 0.9688846036),
    27vw -66vh 1px 0.75px #f3f6f1,
    21vw -38vh 1px 0.75px rgba(241, 249, 243, 0.8680567471),
    -15vw -3vh 1px 0.75px rgba(252, 244, 250, 0.8741643268),
    -95vw -40vh 1px 0.75px #f1f4ff, -88vw 14vh 1px 0.75px #fcf3fb,
    -83vw 12vh 1px 0.75px #f6f8fe, -61vw 39vh 1px 0.75px #f7f2ff,
    41vw 34vh 1px 0.75px rgba(246, 247, 248, 0.9318846392),
    -53vw -73vh 1px 0.75px rgba(255, 250, 247, 0.9158435917),
    -39vw 28vh 1px 0.75px #f8f8fa, 73vw -66vh 1px 0.75px #f1fbf5,
    82vw -1vh 1px 0.75px rgba(242, 242, 242, 0.8414716373),
    92vw -27vh 1px 0.75px rgba(245, 251, 250, 0.9663508769),
    28vw -64vh 1px 0.75px #f1f9f1, 76vw 82vh 1px 0.75px #f1f9f6,
    53vw -70vh 1px 0.75px #f7fdf7,
    -11vw -70vh 1px 0.75px rgba(251, 253, 241, 0.5709810522),
    -73vw -40vh 1px 0.75px #f9f1fb, -36vw 41vh 1px 0.75px #fdf1fe,
    -12vw -9vh 1px 0.75px rgba(247, 244, 242, 0.8454906488),
    60vw 62vh 1px 0.75px #fdfaf4,
    -83vw 87vh 1px 0.75px rgba(245, 241, 244, 0.9176305322),
    44vw -54vh 1px 0.75px rgba(242, 252, 248, 0.9693348061),
    36vw -32vh 1px 0.75px #f9fafa, 41vw -61vh 1px 0.75px #f2faf8,
    42vw -49vh 1px 0.75px rgba(249, 246, 254, 0.9134938038),
    -52vw 53vh 1px 0.75px #f3feff,
    3vw -97vh 1px 0.75px rgba(249, 245, 247, 0.8889316068),
    -90vw 46vh 1px 0.75px #f8fafd,
    23vw -29vh 1px 0.75px rgba(246, 247, 250, 0.6862141599),
    -33vw -63vh 1px 0.75px rgba(243, 253, 249, 0.6395580736),
    -2vw 50vh 1px 0.75px #f5faff,
    -96vw 67vh 1px 0.75px rgba(255, 241, 243, 0.8407892564),
    29vw -95vh 1px 0.75px #f5f5f3, 3vw -27vh 1px 0.75px #fbfef6,
    -87vw 83vh 1px 0.75px rgba(253, 248, 246, 0.8165139252),
    47vw 57vh 1px 0.75px #f9f5f1,
    -9vw -14vh 1px 0.75px rgba(245, 243, 246, 0.6535044176),
    54vw 31vh 1px 0.75px #fff5f3, 25vw -52vh 1px 0.75px #f7faf3,
    91vw -6vh 1px 0.75px #f8fff8,
    -21vw 88vh 1px 0.75px rgba(253, 245, 248, 0.7648848183),
    -29vw 45vh 1px 0.75px rgba(253, 241, 244, 0.6657591369),
    61vw -47vh 1px 0.75px #f3fcf6, 57vw 23vh 1px 0.75px #f9f3fa,
    -90vw 100vh 1px 0.75px rgba(251, 244, 248, 0.9775995177),
    -27vw -93vh 1px 0.75px rgba(248, 251, 250, 0.5803350999),
    28vw -29vh 1px 0.75px #f9f4fc, -80vw 67vh 1px 0.75px #f9f9fe,
    90vw -18vh 1px 0.75px rgba(251, 251, 252, 0.6814187659),
    -82vw 27vh 1px 0.75px rgba(244, 248, 243, 0.893709482),
    -66vw 33vh 1px 0.75px #f1f8fc,
    97vw 2vh 1px 0.75px rgba(244, 250, 241, 0.5214614359),
    -31vw -34vh 1px 0.75px rgba(245, 248, 244, 0.5625745825),
    40vw -33vh 1px 0.75px #f6f6f9, 10vw 47vh 1px 0.75px #fefcf4,
    -49vw -20vh 1px 0.75px #f3f3f7,
    -61vw -11vh 1px 0.75px rgba(250, 244, 248, 0.7422571864),
    -67vw -2vh 1px 0.75px rgba(254, 243, 243, 0.8733439445),
    -39vw 17vh 1px 0.75px #f8f2f1, -36vw -56vh 1px 0.75px #f8faf6,
    18vw -36vh 1px 0.75px rgba(249, 255, 248, 0.7808456449),
    -91vw 37vh 1px 0.75px #fcfef1, -67vw -26vh 1px 0.75px #f4f4fc,
    50vw -79vh 1px 0.75px rgba(245, 244, 252, 0.6027408961),
    73vw 82vh 1px 0.75px rgba(243, 252, 255, 0.575434172),
    -24vw -97vh 1px 0.75px #f4f6ff, -87vw -23vh 1px 0.75px #fffcf9,
    81vw 78vh 1px 0.75px rgba(250, 251, 255, 0.800520426),
    -18vw -98vh 1px 0.75px #fbfefc, 19vw -33vh 1px 0.75px #fefdff,
    -36vw -21vh 1px 0.75px #f3f4f2,
    16vw -39vh 1px 0.75px rgba(243, 254, 247, 0.726988897),
    47vw -57vh 1px 0.75px rgba(248, 244, 245, 0.5195989269),
    -83vw -81vh 1px 0.75px #fefdf2,
    -44vw -19vh 1px 0.75px rgba(245, 242, 243, 0.8433148122),
    58vw -37vh 1px 0.75px rgba(255, 245, 249, 0.9858396814),
    61vw -76vh 1px 0.75px rgba(249, 247, 245, 0.7973323753),
    -59vw 45vh 1px 0.75px rgba(246, 254, 254, 0.5407901041),
    -20vw 73vh 1px 0.75px #fef8f1, -57vw -77vh 1px 0.75px #fefff2,
    -84vw 20vh 1px 0.75px rgba(247, 250, 254, 0.620131291),
    77vw 20vh 1px 0.75px #f6fafd,
    87vw -15vh 1px 0.75px rgba(249, 250, 246, 0.7752325345),
    19vw 60vh 1px 0.75px #fbfafb, 84vw 37vh 1px 0.75px #f5fef7,
    69vw -79vh 1px 0.75px #fff4fb, 64vw -90vh 1px 0.75px #f2f2f2,
    70vw 21vh 1px 0.75px #fcf8f3,
    -63vw 86vh 1px 0.75px rgba(241, 253, 250, 0.881736872),
    -99vw -89vh 1px 0.75px rgba(255, 255, 245, 0.808938129),
    13vw 58vh 1px 0.75px rgba(251, 247, 251, 0.6117021217),
    69vw -43vh 1px 0.75px rgba(241, 252, 255, 0.7327540053),
    35vw -94vh 1px 0.75px #f4fbf5,
    21vw -13vh 1px 0.75px rgba(251, 253, 244, 0.5327758874),
    -6vw -99vh 1px 0.75px #fdfdf4, -28vw -81vh 1px 0.75px #fdf6fb,
    -91vw 39vh 1px 0.75px #f1f2f1;
  width: 1px;
  height: 1px;
}

div.starfield .moving-1 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  transform-origin: -31vw -38vh;
  box-shadow: 98vw -71vh 1px 0.75px rgba(251, 254, 249, 0.5796560731),
    60vw -74vh 1px 0.75px #f8f7f7,
    28vw 43vh 1px 0.75px rgba(244, 241, 248, 0.6620956293),
    46vw -74vh 1px 0.75px #f5f9f9, 23vw 30vh 1px 0.75px #f2f6f2,
    -63vw 77vh 1px 0.75px #f6f6f9,
    -30vw 20vh 1px 0.75px rgba(255, 254, 242, 0.95287468),
    -55vw -26vh 1px 0.75px rgba(241, 244, 253, 0.883166328),
    26vw 2vh 1px 0.75px #f2f3f7,
    71vw -60vh 1px 0.75px rgba(248, 252, 241, 0.5509532549),
    74vw -17vh 1px 0.75px rgba(249, 246, 244, 0.7413498004),
    96vw 16vh 1px 0.75px rgba(247, 246, 253, 0.6869293477),
    -36vw 53vh 1px 0.75px #fbf8f5, 22vw 94vh 1px 0.75px #f1f2fe,
    -59vw 90vh 1px 0.75px #f5f5f1, 45vw 59vh 1px 0.75px #fbf2f5,
    -59vw 90vh 1px 0.75px rgba(252, 245, 252, 0.937959723),
    42vw -83vh 1px 0.75px rgba(244, 251, 247, 0.759251987),
    -29vw 83vh 1px 0.75px #f5fdf4,
    34vw -96vh 1px 0.75px rgba(248, 254, 254, 0.547830485),
    16vw -5vh 1px 0.75px rgba(251, 245, 252, 0.8815244488),
    69vw 64vh 1px 0.75px #fcfcf4,
    13vw -25vh 1px 0.75px rgba(254, 251, 242, 0.9875626299),
    94vw -52vh 1px 0.75px rgba(246, 249, 244, 0.7827653594),
    53vw 40vh 1px 0.75px #f5fbf3, -36vw 27vh 1px 0.75px #f7faff,
    -16vw 48vh 1px 0.75px #fef8f9,
    -46vw -68vh 1px 0.75px rgba(253, 241, 252, 0.8559579086),
    -31vw -88vh 1px 0.75px rgba(241, 241, 243, 0.5032175611),
    61vw 91vh 1px 0.75px rgba(251, 244, 248, 0.5219877666),
    -82vw 27vh 1px 0.75px #f4f9f7, 19vw -96vh 1px 0.75px #f5fdf4,
    -74vw -74vh 1px 0.75px #f2fef5,
    -30vw 86vh 1px 0.75px rgba(247, 250, 243, 0.996487551),
    92vw -2vh 1px 0.75px rgba(252, 245, 246, 0.7235354241),
    99vw 1vh 1px 0.75px rgba(248, 250, 250, 0.9360883972),
    88vw -73vh 1px 0.75px #fafbfc, 77vw 96vh 1px 0.75px #faf6fa,
    17vw 95vh 1px 0.75px rgba(244, 248, 241, 0.5054011713),
    42vw 5vh 1px 0.75px rgba(246, 249, 254, 0.785195719),
    -97vw 68vh 1px 0.75px rgba(251, 245, 245, 0.7938347435),
    7vw -95vh 1px 0.75px rgba(243, 249, 255, 0.5113630258),
    75vw -99vh 1px 0.75px #f3fcf7, 23vw -96vh 1px 0.75px #f1fdfd,
    7vw 37vh 1px 0.75px rgba(242, 251, 254, 0.7921848084),
    -76vw 50vh 1px 0.75px rgba(254, 251, 253, 0.5466062608),
    -89vw -47vh 1px 0.75px #f7f4fc, 64vw 60vh 1px 0.75px #f5f3f4,
    35vw -93vh 1px 0.75px rgba(244, 244, 253, 0.9113821496),
    -32vw 14vh 1px 0.75px rgba(255, 249, 247, 0.5448893182),
    93vw 79vh 1px 0.75px rgba(243, 253, 243, 0.5715573667),
    30vw -37vh 1px 0.75px rgba(246, 252, 252, 0.5706466807),
    56vw 4vh 1px 0.75px rgba(248, 248, 252, 0.8380061132),
    29vw -89vh 1px 0.75px rgba(245, 251, 241, 0.7265369866),
    85vw -99vh 1px 0.75px rgba(247, 248, 252, 0.7803772343),
    -10vw 9vh 1px 0.75px rgba(250, 249, 253, 0.5637405381),
    44vw 22vh 1px 0.75px rgba(246, 243, 241, 0.8564573626),
    79vw 67vh 1px 0.75px #fff4fe,
    3vw -54vh 1px 0.75px rgba(253, 253, 254, 0.70205046),
    -36vw -14vh 1px 0.75px rgba(249, 250, 244, 0.9694684202),
    32vw -34vh 1px 0.75px #fffffb,
    -6vw -95vh 1px 0.75px rgba(253, 252, 252, 0.9187564777),
    96vw -45vh 1px 0.75px rgba(242, 250, 252, 0.8899203059),
    79vw -68vh 1px 0.75px rgba(253, 245, 244, 0.7345401342),
    -19vw 60vh 1px 0.75px #f1fbfb, 33vw 97vh 1px 0.75px #fbf1ff,
    -28vw 25vh 1px 0.75px rgba(242, 248, 245, 0.5307262917),
    -41vw -98vh 1px 0.75px #faf6f1, -24vw -83vh 1px 0.75px #fff4f9,
    45vw -20vh 1px 0.75px rgba(248, 243, 250, 0.5240054996),
    28vw 39vh 1px 0.75px rgba(250, 243, 250, 0.5274017566),
    90vw 31vh 1px 0.75px #f8f6f9, -96vw -21vh 1px 0.75px #f8f7f2,
    96vw 9vh 1px 0.75px rgba(247, 251, 247, 0.7435375515),
    -64vw -52vh 1px 0.75px #f5f8f3,
    95vw -87vh 1px 0.75px rgba(243, 248, 243, 0.9133779279),
    39vw -71vh 1px 0.75px #fbfcfa, -58vw 0vh 1px 0.75px #fbf5f7,
    -99vw -40vh 1px 0.75px #fff8f1,
    80vw 84vh 1px 0.75px rgba(243, 243, 254, 0.8503211098),
    -46vw 2vh 1px 0.75px rgba(253, 242, 245, 0.5192076591),
    -99vw 54vh 1px 0.75px rgba(252, 241, 246, 0.7197874037),
    -71vw -13vh 1px 0.75px rgba(245, 253, 242, 0.8075053814),
    8vw 77vh 1px 0.75px #f5f1f1, 80vw -98vh 1px 0.75px #fff8f1,
    -35vw -4vh 1px 0.75px #f6f3fb, -5vw -90vh 1px 0.75px #fbfdf1,
    90vw -29vh 1px 0.75px rgba(251, 249, 241, 0.807057173),
    -20vw 73vh 1px 0.75px rgba(246, 242, 252, 0.5286088298),
    -46vw 83vh 1px 0.75px #f2faf1, -18vw 13vh 1px 0.75px #f1f2fc,
    70vw 50vh 1px 0.75px #fff9fa,
    -82vw -18vh 1px 0.75px rgba(249, 254, 255, 0.835895734),
    27vw -52vh 1px 0.75px rgba(246, 243, 243, 0.5915919663),
    79vw -97vh 1px 0.75px #f3faf6,
    -27vw -84vh 1px 0.75px rgba(246, 245, 249, 0.6195757299),
    25vw 95vh 1px 0.75px #f6f6fd,
    -83vw 95vh 1px 0.75px rgba(248, 250, 255, 0.995678514),
    18vw -96vh 1px 0.75px #fafbf9, -87vw -52vh 1px 0.75px #f6fcf4;
  animation: star-movement 9s cubic-bezier(0.55, 0, 1, 0.45) infinite,
    direction-movement 30s ease-in-out alternate infinite;
}

div.starfield .moving-2 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  transform-origin: -31vw -38vh;
  box-shadow: 40vw -74vh 1px 0.75px rgba(243, 253, 243, 0.6976853084),
    -93vw 42vh 1px 0.75px rgba(241, 241, 245, 0.5218902844),
    -6vw 27vh 1px 0.75px #f3ffff, -21vw -9vh 1px 0.75px #fef9f9,
    70vw -13vh 1px 0.75px rgba(245, 253, 246, 0.632556686),
    24vw -76vh 1px 0.75px rgba(247, 241, 242, 0.6379328074),
    36vw -80vh 1px 0.75px #f1faf9, 33vw -49vh 1px 0.75px #f4fdf6,
    23vw 2vh 1px 0.75px rgba(244, 250, 246, 0.7003595363),
    -46vw 41vh 1px 0.75px #fef6f3,
    49vw -62vh 1px 0.75px rgba(249, 251, 241, 0.7464029821),
    36vw 62vh 1px 0.75px #f3fafe, 87vw 44vh 1px 0.75px #f8f6f6,
    24vw 70vh 1px 0.75px rgba(243, 249, 250, 0.9627452695),
    -36vw 81vh 1px 0.75px rgba(245, 241, 250, 0.8348169547),
    65vw -86vh 1px 0.75px rgba(251, 250, 255, 0.6697964858),
    -78vw 27vh 1px 0.75px rgba(251, 250, 245, 0.6678135489),
    -99vw 84vh 1px 0.75px #fbfbf2,
    -66vw 63vh 1px 0.75px rgba(248, 247, 251, 0.6429325607),
    54vw -53vh 1px 0.75px rgba(245, 241, 252, 0.8622728604),
    -51vw -13vh 1px 0.75px #fcf7f2,
    -93vw -91vh 1px 0.75px rgba(246, 242, 242, 0.7987353667),
    80vw 91vh 1px 0.75px #f8f5f3, 67vw -66vh 1px 0.75px #f9f3f1,
    47vw 78vh 1px 0.75px rgba(245, 250, 244, 0.74760595),
    -22vw 57vh 1px 0.75px rgba(246, 247, 247, 0.7581258614),
    -96vw -5vh 1px 0.75px #f9f5f5, 82vw -26vh 1px 0.75px #f3f7fc,
    62vw -6vh 1px 0.75px #f2f7f1,
    49vw 83vh 1px 0.75px rgba(248, 251, 252, 0.7898566349),
    30vw 45vh 1px 0.75px rgba(247, 241, 253, 0.8091298881),
    -24vw -54vh 1px 0.75px #f7fcf8,
    53vw -22vh 1px 0.75px rgba(254, 244, 249, 0.573485027),
    -48vw 88vh 1px 0.75px #f3f8f8,
    -89vw -52vh 1px 0.75px rgba(247, 249, 249, 0.6259997331),
    0vw 82vh 1px 0.75px #f1f7f5,
    -25vw 9vh 1px 0.75px rgba(252, 242, 255, 0.5956961941),
    -7vw 31vh 1px 0.75px rgba(253, 253, 247, 0.553963104),
    35vw 62vh 1px 0.75px rgba(254, 245, 254, 0.8865271661),
    -9vw -53vh 1px 0.75px #f8fbfa, 10vw -81vh 1px 0.75px #faf4fa,
    -75vw 8vh 1px 0.75px #f6f1f2, 50vw -51vh 1px 0.75px #fbf9fc,
    99vw 32vh 1px 0.75px #fffaf1,
    -98vw -1vh 1px 0.75px rgba(248, 245, 249, 0.5978577971),
    79vw -65vh 1px 0.75px rgba(243, 243, 245, 0.8759631083),
    18vw 29vh 1px 0.75px #f5f3f6, -90vw -94vh 1px 0.75px #f2f5fb,
    -4vw -51vh 1px 0.75px #f2fef6, 62vw 71vh 1px 0.75px #f9f6fc,
    70vw 33vh 1px 0.75px rgba(243, 246, 249, 0.8400624172),
    -14vw 71vh 1px 0.75px rgba(249, 246, 241, 0.719868869),
    77vw -49vh 1px 0.75px #f9f9f4, 56vw -68vh 1px 0.75px #f1f1f6,
    87vw -50vh 1px 0.75px #fdf9fd,
    -13vw 24vh 1px 0.75px rgba(254, 255, 245, 0.8050234245),
    20vw -5vh 1px 0.75px rgba(254, 249, 255, 0.7744509303),
    29vw -22vh 1px 0.75px rgba(254, 252, 249, 0.8683826021),
    54vw -35vh 1px 0.75px snow, 19vw -30vh 1px 0.75px #f5f5f3,
    99vw -48vh 1px 0.75px rgba(245, 245, 249, 0.5407473116),
    -84vw -26vh 1px 0.75px #f7f5fe, 38vw -24vh 1px 0.75px #f1fbf6,
    -48vw 33vh 1px 0.75px rgba(246, 255, 254, 0.6254991258),
    28vw 15vh 1px 0.75px rgba(250, 245, 253, 0.8310338887),
    73vw 91vh 1px 0.75px rgba(253, 242, 254, 0.7767698708),
    -65vw 96vh 1px 0.75px #f4f1f8,
    8vw -48vh 1px 0.75px rgba(253, 246, 246, 0.8645967089),
    -22vw -85vh 1px 0.75px #f9f3f8,
    36vw 63vh 1px 0.75px rgba(254, 253, 241, 0.6785751691),
    99vw 37vh 1px 0.75px rgba(247, 247, 247, 0.582057336),
    56vw -76vh 1px 0.75px #fffcfd,
    83vw -12vh 1px 0.75px rgba(247, 250, 248, 0.8136677806),
    26vw -12vh 1px 0.75px #fef9fd,
    100vw 82vh 1px 0.75px rgba(250, 242, 248, 0.6861693788),
    73vw -21vh 1px 0.75px #fbfcfb,
    -91vw -24vh 1px 0.75px rgba(253, 245, 241, 0.7598353681),
    94vw 49vh 1px 0.75px rgba(254, 252, 251, 0.7513094056),
    -18vw 14vh 1px 0.75px rgba(247, 241, 247, 0.8442176478),
    -80vw 99vh 1px 0.75px #fcf3f8, -21vw 22vh 1px 0.75px #fdfaf6,
    9vw 56vh 1px 0.75px rgba(241, 252, 250, 0.9484880841),
    -86vw 63vh 1px 0.75px #fffff7,
    97vw 93vh 1px 0.75px rgba(246, 243, 246, 0.7090860489),
    -47vw 100vh 1px 0.75px #fbf6f6, 57vw -62vh 1px 0.75px #f9f2f5,
    -60vw 60vh 1px 0.75px rgba(250, 248, 246, 0.8128038178),
    -63vw 85vh 1px 0.75px #fcfcf9, -76vw -58vh 1px 0.75px #f4faf4,
    -8vw 93vh 1px 0.75px rgba(253, 250, 245, 0.7038606127),
    -95vw 32vh 1px 0.75px rgba(242, 252, 245, 0.8777265504),
    53vw -8vh 1px 0.75px #f7f9f6, 49vw -13vh 1px 0.75px #f5f9fd,
    -41vw -60vh 1px 0.75px #f1faf1,
    -67vw 41vh 1px 0.75px rgba(254, 255, 254, 0.7231559202),
    2vw 68vh 1px 0.75px #f9f3f6, -63vw 100vh 1px 0.75px #fbf1fc,
    -82vw 41vh 1px 0.75px #f8fff9,
    -72vw 95vh 1px 0.75px rgba(246, 244, 254, 0.8975317144),
    -42vw -56vh 1px 0.75px rgba(248, 253, 247, 0.5535049695);
  animation: star-movement 9s -3s cubic-bezier(0.55, 0, 1, 0.45) infinite,
    direction-movement 30s ease-in-out alternate infinite;
}

div.starfield .moving-3 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  transform-origin: -31vw -38vh;
  box-shadow: -44vw 6vh 1px 0.75px #f2f3fb,
    -80vw 15vh 1px 0.75px rgba(243, 250, 243, 0.516430015),
    27vw -53vh 1px 0.75px #f3f7f6, -72vw 67vh 1px 0.75px #f2f7fd,
    67vw -22vh 1px 0.75px #fef1fe,
    84vw 49vh 1px 0.75px rgba(251, 243, 255, 0.6223981162),
    -99vw 33vh 1px 0.75px #fdf7f1, -19vw 69vh 1px 0.75px #f1f4f3,
    24vw 62vh 1px 0.75px rgba(249, 244, 245, 0.847846961),
    -48vw 15vh 1px 0.75px rgba(252, 252, 243, 0.9462591898),
    -18vw 42vh 1px 0.75px rgba(242, 252, 248, 0.5148233329),
    -7vw -11vh 1px 0.75px rgba(254, 251, 242, 0.523792914),
    -37vw 21vh 1px 0.75px rgba(253, 251, 250, 0.6881076159),
    -47vw -23vh 1px 0.75px rgba(243, 242, 243, 0.6893285365),
    -79vw -28vh 1px 0.75px #f4f5fd,
    -33vw 85vh 1px 0.75px rgba(243, 241, 253, 0.8328932503),
    63vw -7vh 1px 0.75px #fdf7ff, 94vw 26vh 1px 0.75px #f1fefc,
    -21vw 27vh 1px 0.75px rgba(244, 241, 251, 0.9303236812),
    -3vw -78vh 1px 0.75px rgba(254, 245, 245, 0.8262886947),
    50vw -42vh 1px 0.75px #f6fef4, 75vw 7vh 1px 0.75px #fbf4f7,
    52vw -37vh 1px 0.75px #f1f6f8, 11vw 64vh 1px 0.75px #f7f2fe,
    -89vw 23vh 1px 0.75px rgba(245, 242, 250, 0.5728733726),
    38vw -48vh 1px 0.75px #f4fdf7, 61vw 66vh 1px 0.75px #f5f3f5,
    -25vw -43vh 1px 0.75px #f8f3f4,
    6vw 77vh 1px 0.75px rgba(241, 246, 252, 0.7924307884),
    66vw 56vh 1px 0.75px rgba(247, 247, 248, 0.7896422377),
    95vw 2vh 1px 0.75px rgba(249, 247, 246, 0.590453276),
    9vw -33vh 1px 0.75px #fefcff,
    -44vw -94vh 1px 0.75px rgba(250, 249, 248, 0.7011202204),
    43vw 0vh 1px 0.75px #fdfaf8,
    43vw 87vh 1px 0.75px rgba(250, 241, 246, 0.7653099926),
    -18vw -25vh 1px 0.75px rgba(241, 249, 241, 0.5380415257),
    -16vw 42vh 1px 0.75px #f9fef1,
    -82vw 62vh 1px 0.75px rgba(243, 241, 243, 0.7823410134),
    -88vw -31vh 1px 0.75px rgba(245, 249, 243, 0.5612056516),
    -84vw 69vh 1px 0.75px #f5fcf9,
    -40vw 4vh 1px 0.75px rgba(246, 245, 252, 0.6526124422),
    -18vw 32vh 1px 0.75px #f6f9f8,
    -26vw 47vh 1px 0.75px rgba(252, 247, 243, 0.5120760699),
    11vw 74vh 1px 0.75px #f4f4f6,
    88vw 64vh 1px 0.75px rgba(254, 242, 255, 0.6278793349),
    1vw 100vh 1px 0.75px #fdf5fb, -12vw -52vh 1px 0.75px #fff1fa,
    -81vw 2vh 1px 0.75px #f9f9fa,
    -74vw 78vh 1px 0.75px rgba(253, 247, 250, 0.6531596142),
    29vw 35vh 1px 0.75px rgba(254, 244, 244, 0.5155631285),
    -50vw 11vh 1px 0.75px #f4fcfc,
    -95vw -18vh 1px 0.75px rgba(245, 241, 241, 0.5907147267),
    75vw 76vh 1px 0.75px rgba(252, 253, 245, 0.6198726616),
    8vw 26vh 1px 0.75px rgba(242, 254, 244, 0.6958144356),
    -84vw -22vh 1px 0.75px #f6f5fd, 55vw -21vh 1px 0.75px #fdf1fa,
    -27vw 38vh 1px 0.75px rgba(241, 248, 248, 0.5923234092),
    -41vw -69vh 1px 0.75px rgba(244, 243, 250, 0.7658755818),
    99vw 71vh 1px 0.75px rgba(253, 251, 248, 0.7687157225),
    -35vw -13vh 1px 0.75px rgba(254, 255, 246, 0.9431341704),
    -66vw -30vh 1px 0.75px #f8f3f2, -54vw -97vh 1px 0.75px #f5fffe,
    -31vw 73vh 1px 0.75px rgba(242, 249, 249, 0.6221945971),
    -78vw 51vh 1px 0.75px rgba(241, 255, 244, 0.9122044389),
    74vw -79vh 1px 0.75px rgba(249, 250, 253, 0.6143912375),
    -85vw -54vh 1px 0.75px #fff7ff, 8vw -50vh 1px 0.75px #f3fbf5,
    -55vw 8vh 1px 0.75px #fff5f9, 49vw -98vh 1px 0.75px #f1f3fd,
    -87vw 27vh 1px 0.75px rgba(248, 242, 246, 0.7316524966),
    -50vw 43vh 1px 0.75px rgba(242, 255, 242, 0.9689863526),
    -81vw -81vh 1px 0.75px rgba(247, 245, 251, 0.7452617459),
    8vw -90vh 1px 0.75px #f7f8f1,
    -10vw 52vh 1px 0.75px rgba(245, 251, 242, 0.8692656615),
    -30vw -52vh 1px 0.75px #f9fff1, 49vw -46vh 1px 0.75px #f2f6f8,
    63vw 99vh 1px 0.75px #f7f9fd, -67vw 14vh 1px 0.75px #f1f8fc,
    69vw 95vh 1px 0.75px #f3f3f2,
    56vw -55vh 1px 0.75px rgba(247, 253, 253, 0.7457878729),
    -4vw 84vh 1px 0.75px rgba(242, 252, 243, 0.7350566017),
    -61vw -68vh 1px 0.75px rgba(245, 252, 243, 0.5956419672),
    -30vw 88vh 1px 0.75px rgba(247, 249, 245, 0.7603800395),
    80vw 41vh 1px 0.75px #fefdf7,
    -33vw 88vh 1px 0.75px rgba(248, 252, 250, 0.6284337101),
    -45vw 3vh 1px 0.75px #fdf8fd, 71vw 57vh 1px 0.75px #fbfcf5,
    -67vw 36vh 1px 0.75px #fffef1, 16vw 32vh 1px 0.75px #f2fff9,
    -8vw 9vh 1px 0.75px rgba(249, 245, 243, 0.5095446679),
    -10vw -57vh 1px 0.75px rgba(253, 255, 246, 0.5957843896),
    56vw 92vh 1px 0.75px rgba(244, 244, 248, 0.6443797484),
    62vw 51vh 1px 0.75px rgba(254, 241, 255, 0.6773818306),
    -41vw 29vh 1px 0.75px rgba(245, 248, 242, 0.8849121272),
    75vw 74vh 1px 0.75px #f3fefd, -20vw 91vh 1px 0.75px #fcfaf1,
    -49vw 56vh 1px 0.75px #fbfbf3,
    -80vw -50vh 1px 0.75px rgba(241, 241, 242, 0.8231185693),
    -97vw 13vh 1px 0.75px #f6faf2,
    43vw -50vh 1px 0.75px rgba(253, 255, 252, 0.8920248562);
  animation: star-movement 9s -6s cubic-bezier(0.55, 0, 1, 0.45) infinite,
    direction-movement 30s ease-in-out alternate infinite;
}

@keyframes star-movement {
  0% {
    transform: scale(0.5) translateZ(0);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  100% {
    transform: scale(2) translateZ(0);
    opacity: 0;
  }
}

@keyframes direction-movement {
  from {
    transform-origin: -12vw -2vh;
  }

  to {
    transform-origin: 25vw 23vh;
  }
}

div.word {
  position: absolute;
  right: 1vw;
  text-align: right;
  font-size: 2vh;
  animation: word-appear 60s infinite;
  opacity: 0;
  transform: translate3d(calc(1vw + 100%), 0, 0);
}

@keyframes word-appear {
  0% {
    opacity: 0;
    transform: translate3d(calc(1vw + 100%), 0, 0);
  }

  2% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  80% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}

//
// Base styles
//

.card {
  margin: 0 auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  max-width: 475px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(153, 153, 153, 0.25);
  padding: 0.75rem;
  z-index: 99;
  position: relative;
}

.card-image {
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 65%;
  background-image: url("https://assets.codepen.io/285131/coffee_1.jpg");
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: 0 5%;
  position: relative;
}

.card-heading {
  position: absolute;
  left: 10%;
  top: 15%;
  right: 10%;
  font-size: 1.75rem;
  font-weight: 700;
  color: #735400;
  line-height: 1.222;
}

.card-heading small {
  display: block;
  font-size: 0.75em;
  font-weight: 400;
  margin-top: 0.25em;
}

.card-form {
  padding: 2rem 1rem 0;

  input:focus {
    background: #ffffff;
  }
}

.input {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
  position: relative;
  padding-top: 1.5rem;
}

.input + .input {
  margin-top: 1.5rem;
}

.input-label {
  color: #8597a3;
  position: absolute;
  top: 1.5rem;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
}

.input-field {
  border: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font: inherit;
  font-size: 1.325rem;
  padding: 0.25rem 0;
  color: #000000;
}

.input-field:focus,
.input-field:valid {
  outline: 0;
  box-shadow: none;
  // border-bottom-color: #6658d3;
}

.input-field:focus + .input-label,
.input-field:valid + .input-label {
  -webkit-transform: translateY(-1.5rem);
  transform: translateY(-1.5rem);
}

.action {
  margin-top: 2rem;
}

.action-button {
  font: inherit;
  font-size: 1.25rem;
  padding: 1em;
  width: 100%;
  font-weight: 500;
  background: linear-gradient(to right, #00ac8d, #02c42a);
  border-radius: 6px;
  color: #fff;
  border: 0;
}

.action-button:focus {
  outline: 0;
}

.card-info {
  padding: 1rem 1rem;
  text-align: center;
  font-size: 0.875rem;
  color: #8597a3;
}

.card-info a {
  display: block;
  color: #6658d3;
  text-decoration: none;
}

.login-btn-b {
  width: 60%;
  padding: 0.6em;
  margin-left: 16%;
}

.login-send-button {
  color: #8597a3;
  position: absolute;
  right: 0px;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
  width: 40%;
  padding: 0px;
  bottom: 10px;
  background-color: #ffffff;
}

.css-ogtd7z {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  transition: all 1s ease 0s;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgb(254, 241, 242);
}
.css-jrzkh7 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  background-color: rgb(24, 26, 32);
}
.css-1aac2e {
  box-sizing: border-box;
  margin: 0px auto;
  min-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 1248px;
  background-color: rgb(254, 241, 242);
}
.css-1wr4jig {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}
.css-xry4yv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  min-height: 600px;
  flex: 1 1 0%;
  flex-direction: column;
}
.css-xry4yv {
  flex-direction: row;
}
.css-foka8b {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.08) 0px 0px 4px;
  position: relative;
  z-index: 1;
  flex-direction: column;
  width: 200px;
  background: #ffffff;
}
.css-160vccy {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  background-color: rgb(250, 250, 250);
}
.css-z87e9z {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  text-decoration: none;
  color: rgb(201, 148, 0);
  border-left: 4px solid #00b897;
  height: 48px;
  background-color: rgb(245, 245, 245);
  font-weight: 500;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.css-10j588g {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
}
.css-iizq59 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(33, 40, 51);
}
.css-14thuu2 {
  box-sizing: border-box;
  margin: 0px 8px;
  min-width: 0px;
  color: rgb(240, 185, 11);
  font-size: 24px;
  fill: #00b897;
  width: 1em;
  flex-shrink: 0;
}
.css-6ijtmk {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  text-decoration: none;
  color: rgb(201, 148, 0);
  border-left: 4px solid transparent;
  height: 48px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: #fff;
}
.css-hd27fe {
  box-sizing: border-box;
  margin: 0px 8px;
  min-width: 0px;
  color: rgb(132, 142, 156);
  font-size: 24px;
  fill: rgb(132, 142, 156);
  width: 1em;
  flex-shrink: 0;
}
.css-1n0484q {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(33, 40, 51);
}

.css-1s52m11 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  color: rgb(30, 35, 41);
  flex-direction: column;
  background-color: rgb(250, 250, 250);
}
.css-b80wxf {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  box-shadow: none;
  background-color: rgb(255, 255, 255);
  padding: 16px;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 4px;
  padding: 24px;
}
.css-1s8q8od {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}
.css-u95vxr {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 32px;
  color: rgb(30, 35, 41);
}
.css-1k0kvxy {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  height: 16px;
  width: 100%;
  display: none;
}
.css-wfexmv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: auto;
  flex-direction: row;
  overflow: auto;
  padding-bottom: 0px;
}
.css-4cffwv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
}
.css-klzfmn {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(201, 148, 0);
  flex: 1 1 auto;
  display: inline-block;
  text-decoration: none !important;
}
a {
  background-color: transparent;
}
.css-z7v3zq {
  margin: 0px;
  min-width: 0px;
  appearance: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  word-break: keep-all;
  color: rgb(33, 40, 51);
  border-radius: 4px;
  padding: 4px 8px;
  min-height: 24px;
  border: none;
  background: #3db485;
  color: #fff;
}
.css-wfexmv button {
  font-weight: 500;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
}
.css-11mfxxz {
  box-sizing: border-box;
  margin: 0px 16px;
  min-width: 0px;
  color: rgb(201, 148, 0);
  flex: 1 1 auto;
  display: inline-block;
  text-decoration: none !important;
}
.css-1tz9k8l {
  margin: 0px;
  min-width: 0px;
  appearance: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  word-break: keep-all;
  color: rgb(33, 40, 51);
  border-radius: 4px;
  padding: 4px 8px;
  min-height: 24px;
  border: none;
  background-color: transparent;
  box-shadow: rgb(234, 236, 239) 0px 0px 0px 1px inset;
}
.css-wfexmv button {
  font-weight: 500;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
}
.css-11ag26e {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  height: 0px;
  box-shadow: none;
  height: 24px;
}
.css-1imiysb {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 4px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 24px;
  margin-right: 24px;
}
.css-87c5r {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 0%;
  flex-direction: row;
}
.css-1n7u5cf {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: row;
}
.css-661iov {
  box-sizing: border-box;
  margin: 8px 8px 8px 0px;
  min-width: auto;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin-right: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.css-10nf7hq {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-oorpkh {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 14px;
  line-height: 20px;
  color: rgb(30, 35, 41);
}
.css-10kvebh {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: none;
}
.css-1iivh6i {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}
.css-d9plw {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(30, 35, 41);
  font-size: 32px;
  line-height: 42px;
}
.css-1qujath {
  box-sizing: border-box;
  margin: 0px 0px 0px 4px;
  min-width: 0px;
  font-size: 14px;
  line-height: 28px;
  color: rgb(71, 77, 87);
}
.css-w37o3p {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  height: 24px;
  background-color: transparent;
  box-shadow: none;
}
.css-9cxewd {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  background-color: rgb(255, 255, 255);
  padding: 0px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 4px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  padding: 24px;
  height: 1090px;
}
.css-1inwbdu {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
}
.css-1ammkwy {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}
.coinbox {
  width: 100%;
  height: 400px;
  margin-top: 20px;
}
.cointitle {
  width: 100%;
  height: 40px;
  background-color: #f5f5f5;
}
.titleop {
  height: 40px;
  line-height: 40px;
  text-align: left;
  float: left;
  padding-left: 15px;
  overflow: hidden;
}
.coincontent {
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
}
.contentop {
  height: 50px;
  line-height: 50px;
  text-align: left;
  float: left;
  padding-left: 15px;
  overflow: hidden;
}
.contentop a:hover {
  color: #00b897;
}

.css-6ul7zn {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  width: 100%;
  padding: 32px;
}
.css-joa6mv {
  box-sizing: border-box;
  margin: 0px 0px 24px;
  min-width: 0px;
}
.css-1868gi1 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}
.css-1h690ep {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
}
.css-jjjwcg {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.css-15owl46 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
}
.btitle {
  width: 100%;
  height: 40px;
  background: #f5f5f5;
}
.btitleop {
  height: 40px;
  line-height: 40px;
  text-align: center;
  float: left;
}
.bcontentop {
  height: 60px;
  line-height: 60px;
  text-align: center;
  float: left;
}
.css-1lzksdc {
  box-sizing: border-box;
  min-width: 0px;
  color: rgb(132, 142, 156);
  fill: rgb(132, 142, 156);
  margin: 16px;
  width: 96px;
  height: 96px;
  font-size: 96px;
}
.minbox {
  width: 100%;
  height: 400px;
  overflow: auto;
}
.css-14thuu2 {
  box-sizing: border-box;
  margin: 0px 8px;
  min-width: 0px;
  color: #00b897;
  font-size: 24px;
  fill: #00b897;
  width: 1em;
  flex-shrink: 0;
}
css-160vccy {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  background-color: rgb(250, 250, 250);
}
.css-z87e9z {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  text-decoration: none;
  color: rgb(201, 148, 0);
  border-left: 4px solid #00b897;
  height: 48px;
  background-color: rgb(245, 245, 245);
  font-weight: 500;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.css-10j588g {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
}
.css-iizq59 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(33, 40, 51);
}
.css-14thuu2 {
  box-sizing: border-box;
  margin: 0px 8px;
  min-width: 0px;
  color: #00b897;
  font-size: 24px;
  fill: #00b897;
  width: 1em;
  flex-shrink: 0;
}
.css-6ijtmk {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  text-decoration: none;
  color: rgb(201, 148, 0);
  border-left: 4px solid transparent;
  height: 48px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: #fff;
}
.css-hd27fe {
  box-sizing: border-box;
  margin: 0px 8px;
  min-width: 0px;
  color: rgb(132, 142, 156);
  font-size: 24px;
  fill: rgb(132, 142, 156);
  width: 1em;
  flex-shrink: 0;
}
.css-1n0484q {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(33, 40, 51);
}
.css-146q23 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  background-color: rgb(255, 255, 255);
}
.css-jlbk6n {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 8px;
}
.css-1e6doj4 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 4px;
  flex: 1 1 0%;
  padding: 24px;
}
.css-6vt7sa {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(30, 35, 41);
  font-weight: 500;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 20px;
  color: rgb(30, 35, 41);
}
.css-1sgz1lk {
  box-sizing: border-box;
  margin: 0px 0px 0px 16px;
  min-width: 0px;
}
.css-ize0sl {
  box-sizing: border-box;
  margin: 0px 0px 4px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-1uoge8i {
  box-sizing: border-box;
  margin: 0px 16px 0px 0px;
  min-width: 0px;
}
.css-180eiyx {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  font-size: 14px;
  color: rgb(30, 35, 41);
}
.css-1ap5wc6 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(112, 122, 138);
}
.css-1124n14 {
  box-sizing: border-box;
  margin: 0px 16px 0px 0px;
  min-width: 0px;
  color: rgb(30, 35, 41);
}
.css-lzd0h4 {
  box-sizing: border-box;
  margin: 0px 0px 0px 8px;
  min-width: 0px;
}
.css-bhso1m {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: inline-block;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  background-color: #00b897;
  color: #fff;
}
.css-1ry7rnu {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  color: rgb(112, 122, 138);
  font-size: 12px;
  line-height: 1.25;
}
.css-9cwl6c {
  box-sizing: border-box;
  margin: 0px 8px 0px 0px;
  min-width: 0px;
}
.css-vurnku {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}
.css-kvkii2 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  width: 66.6667%;
  padding: 8px;
}
.css-1p01izn {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  box-shadow: rgba(20, 21, 26, 0.04) 0px 1px 2px,
    rgba(71, 77, 87, 0.04) 0px 3px 6px, rgba(20, 21, 26, 0.1) 0px 0px 1px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  padding: 0px 16px;
  width: 100%;
}
.css-1hythwr {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
}
.css-1hz1mz6 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  padding-top: 16px;
  padding-bottom: 16px;
}

.css-181kvgz {
  box-sizing: border-box;
  margin: 0px 16px 0px 0px;
  min-width: 0px;
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  color: rgb(30, 35, 41);
  font-size: 16px;
  padding: 0px;
}
.css-181kvgz:hover {
  color: rgb(252, 213, 53);
}
a,
a:active,
a:visited {
  text-decoration: none;
}
.css-10nf7hq {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-4cffwv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
}
.css-noqr05 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: auto;
}
.css-d732j0 {
  margin: 0px 4px;
  min-width: 0px;
  appearance: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  outline: none;
  font-weight: 500;
  line-height: 20px;
  word-break: keep-all;
  color: rgb(33, 40, 51);
  border-radius: 4px;
  padding: 4px 16px;
  border: none;
  background: #3db485;
  color: #fff;
  flex: 1 1 0%;
  min-height: 24px;
  font-size: 12px;
}
.css-1dfql01 {
  margin: 0px 4px;
  min-width: 0px;
  appearance: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  outline: none;
  font-weight: 500;
  line-height: 20px;
  word-break: keep-all;
  color: rgb(33, 40, 51);
  border-radius: 4px;
  padding: 4px 16px;
  border: none;
  background-color: transparent;
  box-shadow: rgba(234, 236, 239, 1) 0px 0px 0px 1px inset;
  flex: 1 1 0%;
  min-height: 24px;
  font-size: 12px;
}
.css-1s6nhe2 {
  box-sizing: border-box;
  margin: 0px 0px 0px 16px;
  min-width: 0px;
  text-decoration: none;
  color: rgb(201, 148, 0);
  display: flex;
}
.css-155meta {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(0, 0, 0);
  font-size: 18px;
  fill: rgb(0, 0, 0);
  width: 1em;
  height: 1em;
}
.css-1s6nhe2:hover {
  text-decoration: underline;
  color: #00b897;
}
.css-vurnku {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}
.css-gnqbje {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: block;
}
.css-ysetcg {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding-top: 48px;
  padding-bottom: 48px;
}
.css-ct0qa6 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  flex-flow: column wrap;
  flex-direction: row;
}
.css-1bliacb {
  box-sizing: border-box;
  margin: 0px;
  min-width: 250px;
  flex: 1 1 0%;
  margin-bottom: 24px;
}
.css-vurnku {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}
.css-1f978ju {
  box-sizing: border-box;
  margin: 0px 0px 8px;
  min-width: 0px;
}
.css-ize0sl {
  box-sizing: border-box;
  margin: 0px 0px 4px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-1kbdyxh {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 14px;
  color: rgb(112, 122, 138);
  display: inline-block;
}
.css-n5mzgu {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  color: rgb(30, 35, 41);
  font-size: 14px;
  line-height: 24px;
}
.css-off8uh {
  box-sizing: border-box;
  margin: 0px 4px 0px 0px;
  min-width: 0px;
  display: flex;
  align-items: flex-end;
}
.css-1t9tl2o {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 32px;
  line-height: 36px;
}
.css-vurnku {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}
.css-omwf4y {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  width: 100%;
  padding: 8px;
  flex-direction: column;
  flex: 1 1 0%;
}
.css-vt77s9 {
  box-sizing: border-box;
  /* margin: 16px 0px 0px; */
  min-width: 0px;
  box-shadow: rgba(20, 21, 26, 0.04) 0px 1px 2px,
    rgba(71, 77, 87, 0.04) 0px 3px 6px, rgba(20, 21, 26, 0.1) 0px 0px 1px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  padding: 0px 16px;
  width: 100%;
  flex: 1 1 0%;
}
.css-1hythwr {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
}
.css-hwv82q {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  border-width: 0px 0px 1px;
  border-style: solid;
  border-color: rgb(234, 236, 239);
  flex: 1 1 0%;
  padding-top: 16px;
  padding-bottom: 16px;
}
.css-5x6ly7 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  -webkit-box-align: center;
  align-items: center;
}

.css-65w75 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 12px;
}

.css-1wuyyej {
  box-sizing: border-box;
  margin: 8px 0px 0px;
  min-width: 0px;
  text-decoration: none;
  display: block;
  border-width: 0px 0px 1px;
  border-style: solid;
  border-color: rgb(234, 236, 239);
  color: rgb(30, 35, 41);
  line-height: 1.5;
  padding-top: 16px;
  padding-bottom: 16px;
}
.css-1joqi3u {
  box-sizing: border-box;
  margin: 16px 0px 0px;
  min-width: 0px;
  text-align: right;
  color: rgb(112, 122, 138);
}
.css-1wuyyej:hover {
  color: #00b897;
}
a,
.css-1wuyyej:active,
.css-1wuyyej:visited {
  text-decoration: none;
}
.css-ei3nni {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  width: 100%;
  padding: 8px;
}
.css-1p01izn {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  box-shadow: rgba(20, 21, 26, 0.04) 0px 1px 2px,
    rgba(71, 77, 87, 0.04) 0px 3px 6px, rgba(20, 21, 26, 0.1) 0px 0px 1px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  padding: 0px 16px;
  width: 100%;
}
.css-1hythwr {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
}
.css-hwv82q {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  border-width: 0px 0px 1px;
  border-style: solid;
  border-color: rgb(234, 236, 239);
  flex: 1 1 0%;
  padding-top: 16px;
  padding-bottom: 16px;
}
.css-1dcd6pv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  padding-top: 16px;
  padding-bottom: 16px;
  flex-direction: column;
}
.css-1h690ep {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
}
.css-phax11 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  padding: 8px;
  flex-direction: column;
  width: 20%;
}
.css-9b6x94 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 12px;
  color: rgb(112, 122, 138);
  line-height: 1.5;
}
.css-1ngrbny {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  padding: 8px;
  flex-direction: row;
  width: 20%;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
}
.css-1pysja1 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
}

.css-kc3i4p {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  border-width: 0px 0px 1px;
  border-style: solid;
  border-color: rgb(234, 236, 239);
  flex: 1 1 0%;
  padding-top: 4px;
  padding-bottom: 4px;
}
.css-1g02g2m {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 14px;
  color: rgb(30, 35, 41);
}
.css-cvky42 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 12px;
  color: rgb(30, 35, 41);
}
.css-4cffwv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
}

.css-1pryf6p {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 12px;
  color: rgb(14, 203, 129);
}
.css-1g02g2m {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 14px;
  color: rgb(30, 35, 41);
}
.css-9biujf {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  color: #00b897;
  line-height: 1.5;
}
.css-1wr4jig {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

.css-1u0m1fa {
  margin: 0px;
  min-width: 0px;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  display: block;
}

.css-fhl5lc {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  height: 48px;
  -webkit-box-align: center;
  align-items: center;
}

.css-1cdfkn6 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  text-decoration: none;
  color: rgb(112, 122, 138);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  transition: color 0.2s ease 0s;
  pointer-events: auto;
}

.css-1bbywci {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  flex-direction: column;
  width: 100%;
  padding: 32px;
}

.css-1pl31wt {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  overflow: unset;
  margin-top: -15px;
  padding-left: 0px;
  padding-right: 0px;
}

.css-k2y2sp {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
}

.css-1868gi1 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.css-x5jwjg {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  align-items: flex-end;
  margin-top: 0px;
}

.css-3kuzxc {
  margin: 0px;
  appearance: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  outline: none;
  font-weight: 500;
  line-height: 20px;
  word-break: keep-all;
  color: rgb(33, 40, 51);
  border-radius: 6px;
  padding: 6px 16px;
  min-height: 24px;
  border: none;
  background-image: none;
  background-color: #00b897;
  color: #fff;
  min-width: 52px;
  height: 40px;
  font-size: 14px;
}

.css-1cdfkn6:hover,
.css-1cdfkn6:active,
.css-1cdfkn6:visited {
  color: #00b897;
  text-decoration: none;
}

.css-z32tht {
  box-sizing: border-box;
  margin: 24px 0px;
  min-width: 0px;
}

.css-joa6mv {
  box-sizing: border-box;
  margin: 0px 0px 24px;
  min-width: 0px;
}

.css-1op9i22 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
}

.rc-table {
  font-size: 12px;
  color: rgb(102, 102, 102);
  transition: opacity 0.3s ease 0s;
  position: relative;
  line-height: 1.5;
  overflow: hidden;
}

.css-xdf65a {
  color: rgb(30, 35, 41);
}

.css-1op9i22 .rc-table-content {
  min-height: 200px;
  overflow-x: auto !important;
}

.rc-table .rc-table-content {
  position: relative;
}

.rc-table table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.css-1op9i22 .rc-table tr th:first-of-type {
  width: 30px;
  padding-left: 24px;
}

.css-1op9i22 .rc-table th {
  padding-top: 12px;
  padding-bottom: 12px;
}

.css-1op9i22 .rc-table th,
.css-1op9i22 .rc-table td {
  padding: 24px 8px;
  transition: box-shadow 0.3s ease 0s;
}

.css-xdf65a td,
.css-xdf65a th {
  vertical-align: top;
  line-height: 20px;
}

.rc-table th,
.rc-table td {
  padding: 12px 16px;
  white-space: nowrap;
}

.rc-table th {
  background: rgb(249, 249, 250);
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-weight: normal;
  transition: background 0.3s ease 0s;
}

th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.rc-table tr {
  transition: all 0.3s ease 0s;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.css-1op9i22 .rc-table tr td:first-of-type {
  padding-left: 24px;
  position: relative;
}

.css-1op9i22 .rc-table th,
.css-1op9i22 .rc-table td {
  padding: 24px 8px;
  transition: box-shadow 0.3s ease 0s;
}

.css-1op9i22 .rc-table-row td {
  vertical-align: middle;
}

.css-xdf65a td,
.css-xdf65a th {
  vertical-align: top;
  line-height: 20px;
}

.rc-table th,
.rc-table td {
  padding: 12px 16px;
  white-space: nowrap;
}

.rc-table td {
  border-bottom: 1px solid rgb(234, 236, 239);
  background-color: rgb(255, 255, 255);
  color: rgb(33, 40, 51);
  font-size: 14px;
}

td {
  display: table-cell;
  vertical-align: inherit;
}

.css-15oyuc7 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  color: rgb(71, 77, 87);
  padding-bottom: 0px;
}

.css-15oyuc7 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  color: rgb(71, 77, 87);
  padding-bottom: 0px;
}

.css-16sm7a0 {
  box-sizing: border-box;
  margin: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-width: 80px;
}

.css-1c82c04 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.css-1f9551p {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: inline-block;
  position: relative;
}

.css-vp41bv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  position: fixed;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 1200;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.css-1bzqcjp {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  background-color: rgb(255, 255, 255);
  animation: 0.3s ease-in-out 0s 1 normal none running animation-1wqz9z0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 40px;
  border-radius: 4px;
}

.css-7m7e43 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 480px;
  height: auto;
}

.css-13r01t2 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  position: relative;
  padding: 20px 24px;
  -webkit-box-align: center;
  align-items: center;
}

.css-ip5w0j {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: rgb(30, 35, 41);
}

.css-17mum7g {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.css-f1b9tf {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(112, 122, 138);
  font-size: 12px;
  fill: rgb(112, 122, 138);
  width: 1em;
}

.css-m0vwdv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  overflow-y: auto;
  padding: 24px 24px 32px;
  height: 484px;
}

.css-3tfm4c {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  height: 24px;
}

.css-16vu25q {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
}

.css-7ng27 {
  box-sizing: border-box;
  margin: 0px 0px 4px;
  min-width: 0px;
  font-size: 14px;
  line-height: 20px;
  color: rgb(71, 77, 87);
}

.css-1im1bnu {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  width: 100%;
  height: 48px;
  background-color: rgb(255, 255, 255);
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(234, 236, 239);
  cursor: auto;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.css-1hvffwr {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 14px;
  color: rgb(183, 189, 198);
  line-height: 20px;
}

.css-1nlwvj5 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 16px;
  fill: #00b897;
  transform: rotate(0deg);
  color: rgb(112, 122, 138);
  width: 1em;
  height: 1em;
}

.css-3tfm4c {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  height: 24px;
}

.css-vurnku {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
}

.css-154a57d {
  box-sizing: border-box;
  margin: 24px 0px 0px;
  min-width: 0px;
  display: inline-flex;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  line-height: 1.6;
  border: 1px solid rgb(234, 236, 239);
  border-radius: 4px;
  width: 100%;
  height: 48px;
}

.css-16fg16t {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  padding: 0px;
  outline: none;
  border: none;
  background-color: inherit;
  opacity: 1;
}

.css-154a57d input {
  padding-left: 12px;
  padding-right: 12px;
}

.css-154a57d input {
  color: rgb(30, 35, 41);
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  &:focus {
    background-color: transparent;
  }
}

.css-154a57d .bn-input-label {
  font-size: 12px;
}

.css-5vzups {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: absolute;
  top: -24px;
  left: 0px;
  line-height: 24px;
  transition-property: top, font-size;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  z-index: 1;
  cursor: text;
  color: rgb(71, 77, 87);
  font-size: 14px;
}

.css-kiaw5d {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-size: 14px;
  line-height: 20px;
}
.css-1q9xby6 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 16px 24px 24px;
}
.css-7y16gy {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
.css-1mo0ycr {
  margin: 0px 8px 0px 0px;
  appearance: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  word-break: keep-all;
  color: rgb(30, 35, 41);
  border-radius: 4px;
  padding: 6px 12px;
  min-height: 24px;
  border: none;
  background-color: rgb(234, 236, 239);
  min-width: 52px;
  width: 128px;
  height: 40px;
}
.css-p3hzj2 {
  margin: 0px;
  appearance: none;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  word-break: keep-all;
  color: rgb(33, 40, 51);
  border-radius: 6px;
  padding: 6px 12px;
  min-height: 24px;
  border: none;
  background-image: none;
  background-color: #00b897;
  color: #fff;
  min-width: 52px;
  width: 128px;
  height: 40px;
}
.css-1im1bnu:hover {
  border-color: #00b897;
}
.css-154a57d:hover {
  border-color: #00b897;
}
.css-vp41bv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  position: fixed;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 1200;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}
.css-snh7a0 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  box-shadow: rgba(20, 21, 26, 0.16) 0px 8px 16px,
    rgba(71, 77, 87, 0.16) 0px 16px 32px, rgba(20, 21, 26, 0.1) 0px 0px 1px;
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  animation: 0.3s ease-in-out 0s 1 normal none running animation-1wqz9z0;
}
.css-hp9x1w {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  background-color: rgb(255, 255, 255);
  position: relative;
  flex-direction: column;
  border-radius: 6px;
  padding-left: 0px;
  padding-right: 0px;
}
.css-hp9x1w > div:first-of-type {
  padding-left: 24px;
  padding-right: 24px;
}
.css-1dcbbbv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  position: relative;
  height: 64px;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: #f5f5f5;
}
.css-4cffwv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
}
.css-e6jk6i {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.css-1ds83c4 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.css-10uq0b5 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(112, 122, 138);
  font-size: 24px;
  fill: rgb(112, 122, 138);
  width: 1em;
}
.css-1gh297f {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.css-1ei9e9v {
  box-sizing: border-box;
  margin: 8px 0px 0px;
  min-width: 0px;
  flex: 1 1 0%;
  overflow-y: auto;
}
.css-i96p0j {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  height: 352px;
}
.css-1qwhk23 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding-left: 24px;
  padding-right: 24px;
}
.css-hwqc42 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  cursor: pointer;
}
.css-1c0gi8w {
  box-sizing: border-box;
  margin: 0px 4px 0px 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 200px;
  flex-shrink: 0;
}
.css-n2vpdm {
  box-sizing: border-box;
  margin: 0px 0px 0px 16px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: flex-start;
}
.css-1c82c04 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.css-yo3oki {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(71, 77, 87);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
}
.css-joa6mv {
  box-sizing: border-box;
  margin: 24px 0px;
  min-width: 0px;
  background: #f5f5f5;
  padding: 10px;
}

.css-1wr4jig {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.css-wp2li4 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow-x: hidden;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.css-19zx9ks {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  font-size: 12px;
  color: #474d57;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 48px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  z-index: 99;
  padding-top: 16px;
  padding-bottom: 10px;
  padding-left: 17%;
  padding-right: 17%;
  line-height: 1.25;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
}
.css-o4bcl2 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #00b897;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #000000;
}
.css-19zx9ks a {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-bottom: 6px;
}
.css-19zx9ks a:hover {
  color: #c99400;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.css-wl17qh {
  box-sizing: border-box;
  margin: 100%px;
  min-width: 0px;
  display: flex;
  height: fit-content;
  min-height: 349px;
}
.css-1s5qj1n {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  position: relative;
  margin-bottom: 32px;
  padding-top: 40px;
  padding-right: 24px;
  padding-left: 17%;
  width: 69%;
}
.css-101kg5g {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-size: 40px;
  color: #1e2329;
  font-weight: 700;
}
.css-vurnku {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
}
.css-wmvdm0 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 44px;
  margin-bottom: 11px;
}
.css-ktxhrn {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-bottom: 32px;
}
.css-qinc3w {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #00b897;
  display: block;
  margin-bottom: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #474d57;
  font-size: 14px;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.css-6f91y1 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-left: 48px;
}
.css-qinc3w:hover {
  color: #c99400;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.css-ogtd7z {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 1s;
  transition: all 1s;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fef1f2;
}
.css-jrzkh7 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  background-color: #181a20;
}
.css-1aac2e {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 1248px;
  background-color: #fef1f2;
}
.css-pnjgxq {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 219px;
  background-color: #181a20;
}
.css-163lzuo {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 36px;
  color: #eaecef;
}
.css-8pdsjp {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  height: 32px;
  margin-top: 4px;
  margin-bottom: 0px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1.6;
  border: 1px solid transparent;
  border-color: #eaecef;
  border-radius: 4px;
  margin-top: 0;
  margin-bottom: 0;
  width: 327px;
  height: 32px;
  padding-left: 11px;
  padding-right: 6px;
  border-radius: 4px;
  background-color: #2b3139;
  border: none;
}
.css-8pdsjp {
  width: 610px;
  height: 48px;
  padding-left: 16px;
  padding-right: 12.5px;
}
.css-16fg16t {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  outline: none;
  border: none;
  background-color: inherit;
  opacity: 1;
}
.css-8pdsjp input {
  color: #1e2329;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
}
.css-8pdsjp input {
  padding-left: 0;
  font-size: 12px;
  color: #eaecef;
  border-radius: 10px;
}
.css-8pdsjp .bn-input-suffix {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 14px;
}
.css-1qppfsi {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #eaecef;
  width: 24px;
  height: 24px;
  font-size: 24px;
  fill: #1e2329;
  fill: #eaecef;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #474d57;
  font-size: 14px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #474d57;
  font-size: 14px;
}
input:focus {
  background: #2b3139;
  outline: 1px solid #2b3139;
}

.css-wp2li4 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow-x: hidden;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.css-19zx9ks {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  font-size: 12px;
  color: #474d57;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 48px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  z-index: 99;
  padding-top: 16px;
  padding-bottom: 10px;
  padding-left: 17%;
  padding-right: 17%;
  line-height: 1.25;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
}
.css-4cffwv {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.css-vkw2w1 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 17%;
  padding-right: 40px;
  padding-top: 24px;
  padding-bottom: 0;
  width: 70%;
}
.css-kxziuu {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-size: 20px;
  color: #1e2329;
  font-weight: 700;
}

.css-ivlfe5 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 24px;
  border-bottom: 1px solid #e6e8ea;
  padding-bottom: 24px;
}
.css-nqy1gg {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-right: 8px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #1e2329;
  border: 1px solid #e6e8ea;
}
.css-178uoae {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #00b897;
  width: 24px;
  height: 24px;
  font-size: 24px;
  fill: #1e2329;
  fill: #00b897;
  width: 100%;
  height: 100%;
}
.css-vurnku {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
}
.css-ccbn3d {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #00b897;
  font-size: 14px;
}
.css-17s7mnd {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #000000;
  font-size: 12px;
}
.css-1ybfxxc {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  overflow-wrap: break-word;
  padding-top: 24px;
  padding-bottom: 32px;
}
.css-1ii68zy {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-weight: 400;
}

.css-3fpgoh {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 24px;
}
.css-3fpgoh {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 24px;
}
.css-3fpgoh {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 16px;
  color: #1e2026;
}
.css-1odg5z2 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  height: 260px;
  background-color: #0d202d;
  position: -webkit-sticky;
  position: sticky;
  top: -256px;
  z-index: 1;
  padding: 0;
  height: 360px;
}
.css-1odg5z2::before {
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../img/banner/bannerissue.png");
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.css-1xrgo9z {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-size: 26px;
  color: white;
  z-index: 1;
  height: 100%;
  padding-bottom: 48px;
}
.css-1xrgo9z {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-size: 40px;
  padding-bottom: 64px;
}
.css-1xrgo9z {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.css-uliqdc {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
.progress-bar {
  color: #000;
  background: linear-gradient(to right, #6299f7, #51d8fe);
}
::-webkit-input-placeholder {
  color: #b5b5b5;
  font-size: 12px;
}
::-moz-placeholder {
  color: #b5b5b5;
  font-size: 12px;
}
input:focus {
  background: #f5f5f5;
  outline: 1px solid #f5f5f5;
}
.allbtn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #ccc;
  border-radius: 5px;
  background: #fbbc01;
  margin-top: 20px;
}
.css-bhso1m {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: inline-block;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  background-color: #3db485;
  color: rgb(240, 185, 11);
}
.css-bhso1ma:hover,
.css-bhso1ma:link,
.css-bhso1ma:visited,
.css-bhso1ma:active {
  color: rgb(240, 185, 11);
  text-decoration: none;
}

.css-bhso1m:hover {
  color: #0d202d !important;
}

.css-1ax9bc0 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.08);
  margin-top: -64px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.css-6mr8u2 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.css-abpumn {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow-y: auto;
  font-weight: 500;
}
.css-1m3syfi {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex-shrink: 0;
  cursor: pointer;
  text-align: center;
  border-color: #3db485;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  background: rgba(255, 255, 255, 0.1);
  padding: 14px 16px;
  line-height: 20px;
  font-size: 14px;
  text-decoration: none !important;
}
.css-1m3syfi {
  border-bottom-width: 4px;
}
.css-1m3syfi {
  padding: 20px 24px;
  font-size: 20px;
}
.css-cbipbt {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #f5f5f5;
}
.css-lk1png {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex-shrink: 0;
  cursor: pointer;
  text-align: center;
  border-color: rgb(240, 185, 11);
  border-bottom-style: solid;
  border-bottom-width: 0px;
  padding: 14px 16px;
  line-height: 20px;
  font-size: 14px;
  text-decoration: none !important;
}
.css-lk1png {
  padding: 20px 24px;
  font-size: 20px;
}

.css-bhso1m:hover {
  color: #0d202d !important;
}

.css-1ay57iv {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  background-color: rgb(250, 250, 250);
  flex: 1 1 0%;
  flex-direction: column;
}
.css-8hzjvg {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 24px 16px;
  padding-left: 24px;
  padding-right: 24px;
}
.css-1eklhqk {
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
}
.css-190uhut {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.css-11y6cix {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
}
.css-146agw4 {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: #00b897;
  font-size: 24px;
  fill: #00b897;
  cursor: pointer;
  width: 32px;
  height: 32px;
  font-size: 32px;
  margin-left: -48px;
}
.css-1djsyd6 {
  box-sizing: border-box;
  margin: 0px 0px 0px 16px;
  min-width: 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.css-1dihobw {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  background-color: rgb(255, 255, 255);
  border-radius: 40px 40px 0px 0px;
  padding: 24px 16px;
  padding-left: 24px;
  padding-right: 24px;
}
.css-1eklhqk {
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
}
.css-tu2ioc {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-flow: column wrap;
  padding-top: 16px;
  padding-bottom: 16px;
  flex-direction: row;
}
.css-1ekpz1z {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  order: 1;
  flex: 1 1 0%;
}
.czbox {
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
}
.czbox_1 {
  width: 30%;
  height: 80px;
  float: left;
}
.czbox_2 {
  width: 70%;
  height: 80px;
  float: right;
}
.czbox_3 {
  width: 100%;
  height: 20px;
  line-height: 20px;
}
.czbox_4 {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-top: 5px;
  border: 1px solid #f5f5f5;
  padding-left: 15px;
  border-radius: 5px;
}
.czbox_4:hover {
  border: 1px solid #fcd535;
}
.czbox_5 {
  width: 50%;
  height: 30px;
  border: 1px solid #fff;
  background-color: #fff;
  color: #000;
  font-size: 14px;
}
.czbox_5:hover {
  border: 1px solid #fff;
  background-color: #fff;
}
.czbox_5 input:focus {
  border: 1px solid #fff;
  background-color: #fff;
}
.czbox_6 {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-top: 5px;
}
.layui-upload-file {
  display: none !important;
  opacity: 0.01;
}

.klinebox {
  width: 100%;
  height: 550px;
  margin-top: 55px;
}
.klinetitle {
  width: 100%;
  height: 45px;
  padding: 0px 20px;
}
.klinetitle_l {
  width: 20%;
  height: 50px;
  float: left;
}
.klinetitle_r {
  width: 80%;
  height: 50px;
  float: left;
}
.newpricebox {
  height: 28px;
  line-height: 30px;
  margin-bottom: 0px;
  color: #fbbc01;
  font-size: 12px;
  text-align: left;
}
.cus-select {
  background: #191b1c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #323232;
  height: 34px;
  border-radius: 4px;
  width: 100%;
  padding: 0 8px;
  select {
    background: transparent;
    border: none;
    width: auto;
    min-width: 100%;
    height: 34px;
    padding: 0;
    text-align: center;
    option {
      background: #000000;
      border: none;
      color: #fbbc01;
      font-size: 16px;
    }
  }
}
.changebox {
  height: 20px;
  line-height: 20px;
  margin-bottom: 0px;
  color: #2ebd85;
  text-align: left;
}
.klr3_box {
  width: 100%;
  height: 30px;
}
.klr2_box {
  width: 100%;
  height: 25px;
}
.klr3_box_dl {
  width: 100%;
  height: 40px;
  line-height: 60px;
  float: left;
}
.klr3_box_dr {
  width: 100%;
  height: 40px;
  line-height: 40px;
  float: right;
}
.klr2_box_dl {
  width: 45%;
  height: 25px;
  line-height: 25px;
  float: left;
  text-align: left;
}
.klr2_box_dr {
  width: 55%;
  height: 25px;
  line-height: 25px;
  float: right;
  text-align: right;
}
.dongbox {
  position: fixed;
  z-index: 9999;
  display: none;
  top: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
}
.dong_con {
  width: 30%;
  height: 86vh;
  background: #fff;
  margin-top: 80px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px 15px 10px 20px;
  float: left;
}
.dong_title {
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.dong_title_span {
  font-size: 18px;
  font-weight: 500;
}
.dong_find_box {
  width: 100%;
  height: 30px;
  background: #f5f5f5;
  border-radius: 10px;
}
.dong_find_box_img {
  width: 20%;
  height: 30px;
  line-height: 30px;
  float: left;
  text-align: center;
}
.dong_input_box {
  width: 80%;
  height: 30px;
  float: left;
}
.dong_symbox {
  width: 90%;
  height: 20px;
  border: none;
  background: #f5f5f5;
  margin-top: 5px;
}
.dong_sel_box {
  width: 100%;
  height: 39px;
  border-bottom: 1px solid #2c2d2e;
}
.dong_sel_span {
  display: block;
  width: 35px;
  height: 39px;
  line-height: 39px;
  border-bottom: 2px solid #1da2b4;
  font-size: 14px;
  text-align: center;
  font-size: 14px;
}
.symbol_list {
  width: 100%;
  height: 450px;
  margin-top: 10px;
}
.sy_list_box {
  width: 100%;
  height: 39px;
}
.sy_list_boxl {
  width: 35%;
  height: 39px;
  line-height: 39px;
  float: left;
  text-align: left;
}
.sy_list_boxr {
  width: 30%;
  height: 39px;
  line-height: 39px;
  float: right;
  text-align: right;
}
.btnbox {
  width: 100%;
  height: 60px;
  padding: 10px 20px;
}
.btnbox_l {
  width: 45%;
  height: 40px;
  line-height: 40px;
  float: left;
  text-align: center;
  border-radius: 10px;
  background: #0ecb81;
}
.btnbox_r {
  width: 45%;
  height: 40px;
  line-height: 40px;
  float: right;
  text-align: center;
  border-radius: 10px;
  background: #f5465c;
}
.dong_order_c {
  width: 100%;
  height: 550px;
  background: #1a1b1c;
  margin: 0;
  padding: 0px 10px;
  line-height: 0px;
}
.dong_order_x {
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: right;
}
.dong_order_title {
  width: 100%;
  height: 30px;
  line-height: 20px;
  text-align: center;
  border-bottom: 1px solid #f5f5f5;
}
.dong_order_option {
  width: 100%;
  min-height: 110px;
  overflow: hidden;
  margin-top: 25px;
  max-height: 180px;
}
.dong_order_option_list {
  width: 100px;
  height: 80px;
  background: #2c2d2e;
  float: left;
  margin-right: 10px;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  margin-top: 10px;
}
.dong_order_p {
  width: 100%;
  margin-top: 5px;
  padding-left: 5px;
  height: 20px;
  line-height: 30px;
}
.dong_money_list {
  width: 100%;
  min-height: 60px;
}
.dong_money_list_box {
  width: 70%;
  max-height: 100px;
  float: left;
  overflow: hidden;
  cursor: pointer;
}
.dong_money_list_box_l {
  height: 40px;
}
.dong_money_list_box_option {
  width: 70px;
  height: 40px;
  line-height: 40px;
  background: #2c2d2e;
  float: left;
  margin-right: 5px;
  border-radius: 5px;
  text-align: center;
  margin-top: 5px;
}
.green {
  color: #3db485;
}
.red {
  color: #ff494a;
}
.left-border-css {
  border-left: 10px solid #151617;
}

.right-border-css {
  border-right: 10px solid #151617;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #2c2d2e;
}
.layui-layer {
  border-radius: 15px !important;
}
.changInterval {
  margin-bottom: 10px;
}
.interval-item {
  height: 25px;
  line-height: 18px;
  margin-right: 10px;
  padding: 4px 6px;
  outline: none;
  transition: all 0.25s;
  cursor: pointer;
  vertical-align: top;
  color: #c7cce6;
  font-size: 12px;
  background: transparent;
  &.active {
    background: #00a4d8 !important;
    color: #fff !important;
    border: none !important;
  }
}
.tradebox_title {
  width: 50%;
  height: 15px;
  line-height: 15px;
  float: left;
}
.trade_listbox {
  width: 100%;
  height: 240px;
  padding: 5px;
  overflow: hidden;
}
.trade_list {
  width: 50%;
  height: 20px;
  line-height: 20px;
  float: left;
}
.trade_listpricebox {
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0px 10px;
  margin-top: 10px;
}
.contentbox {
  width: 100%;
  background: #1a1b1c;
  padding: 10px;
}
.content_title_l {
  width: 70%;
  height: 40px;
  line-height: 40px;
  float: left;
  .mu-btn {
    user-select: none;
    font-size: 18px;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    display: inline-block;
  }
}
.content_title_r {
  width: 30%;
  height: 40px;
  line-height: 40px;
  float: right;
  padding: 0px 5px;
}
.tleft {
  text-align: left;
}
.tright {
  text-align: right;
}
.tradebox {
  width: 100%;
  height: 350px;
  background: #1a1b1c;
}
.tradebox_l {
  width: 58%;
  height: 300px;
  float: left;
}
.tradebox_r {
  width: 40%;
  height: 300px;
  float: right;
}
.tradebox_l_btn {
  width: 100%;
  height: 36px;
}
.tradebox_l_buybtn {
  width: 48%;
  height: 36px;
  line-height: 36px;
  float: left;
  text-align: center;
  border-radius: 5px;
}
.tradebox_l_sellbtn {
  width: 48%;
  height: 36px;
  line-height: 36px;
  float: right;
  text-align: center;
  border-radius: 5px;
}
.bggreen {
  background: #0ecb81;
}
.green {
  color: #3db485;
}
.bgred {
  background: #f5465c;
}
.red {
  color: #ff494a;
}
.bghc {
  background: #f5f5f5;
}
.cfff {
  color: #fff;
}
.c000 {
  color: #000;
}
.formbox {
  width: 100%;
  margin-top: 15px;
}
.formbox_op {
  width: 100%;
  height: 30px;
}
.formbox_op_list {
  line-height: 22px;
  font-weight: 500;
  text-align: left;
  padding: 0 8px;
  font-size: 13px;
  cursor: pointer;
  color: #bdbdbd;
  &.btn_bg_color {
    background: rgba(17, 193, 164, 0.1);
    border-radius: 4px;
    color: #fbbc01;
  }
}
.inputbox {
  width: 100%;
  margin: 10px 0px 0;
}

.inputbox_float {
  width: 60%;
  height: 36px;
  float: left;
}

.xjinput {
  width: 70%;
  background: #1a1b1c;
  border: #fff;
  margin-top: 7px;
  padding: 0px 10px;
  outline: none !important;
  &:focus {
    background: #1a1b1c;
    outline: none;
    box-shadow: none;
  }
}
.input_bi {
  width: 50%;
  height: 26px;
  line-height: 26px;
  float: left;
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
}
.borderright {
  border-right: 1px solid #151617;
}
.bistyle {
  font-size: 16px;
  cursor: pointer;
}
.blbox {
  width: 100%;
  height: 30px;
  margin-top: 15px;
}
.blbox_1 {
  width: 20%;
  height: 30px;
  float: left;
  margin-right: 6%;
  border-radius: 5px;
}
.blbox_2 {
  width: 20%;
  height: 30px;
  float: left;
  border-radius: 5px;
}
.blbox_3 {
  width: 100%;
  height: 10px;
  border-radius: 5px;
}
.bgf5 {
  background: #73797f;
  border-radius: 5px;
  cursor: pointer;
}
.blbox_4 {
  width: 100%;
  height: 20x;
  line-height: 20px;
  text-align: center;
}
.tl {
  text-align: left;
}
.tr {
  text-align: right;
}
.tc {
  text-align: center;
}

.order_title {
  display: inline-block;
  margin-right: 20px;
  font-weight: 1000;
}
.FCD535 {
  border-bottom: 2px solid #3db485;
}
.fccbox {
  width: 100%;
  height: 5px;
  background: #d4d4d4;
}
.wtlistbox {
  width: 100%;
  padding: 0px 10px;
}
.o_title_box {
  width: 50%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #151617;
}
.tlistbox {
  width: 100%;
  clear: both;
  padding: 10px 0px;
}
.tlistbox_1 {
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #151617;
}
.tlistbox_2 {
  width: 100%;
  height: 30px;
}
.tlistbox_3 {
  width: 80%;
  height: 30px;
  line-height: 30px;
}
.tlistbox_4 {
  width: 20%;
  height: 30px;
  line-height: 30px;
}
.tlistbox_5 {
  padding: 5px 10px;
  background: #fcfcfc;
  border-radius: 5px;
}
.tlistbox_6 {
  width: 100%;
  height: 60px;
}
.tlistbox_7 {
  width: 33.33%;
  height: 60px;
}
.tlistbox_8 {
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.tlistbox_9 {
  width: 100%;
  height: 30px;
  line-height: 20px;
}

button[type="submit"] {
  width: 100%;
}
