:root {
  --theme-color: #0062d3;
  --main-color: #0cc77f;
  --body-color: #f7f6fb;
  --content-color: #f0f2f1;
  --text-color: #333333;
  --bs-primary-rgb: rgba(0, 0, 0, 0.66);
  --text-light-color: #2a2a2a;
  --bg-color: #ffffff;
  --menu-color: #ffffff;
  --bg-light-color: #ffffff;
  --menu-light-color: #ffffff;
  --text-light-color: #2a2a2a;
}

*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:after,
:before {
  --tw-content: "";
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body {
  margin: 0;
  line-height: inherit;
  background-color: var(--body-color);
}

#root {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  opacity: 0;
}

::-webkit-scrollbar-track {
  background: none;
}

*,
:after,
:before {
  box-sizing: border-box;
}

*,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.home-top-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  padding: 15px;
  background: var(--theme-color);
  margin: 0;

  .bg_header_name {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 19px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 60%;
  }

  .logo-div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .logo-img {
    width: 115px;
    object-fit: contain;
  }

  .btn_back {
    position: absolute;
    z-index: 9;
    left: 15px;
    top: 15px;
    cursor: pointer;
  }

  .btn_save {
    position: absolute;
    z-index: 9;
    right: 15px;
    top: 15px;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
    padding: 4px 11px;
  }

  .right {
    position: absolute;
    z-index: 9;
    right: 15px;
    top: 13px;
    font-size: 18px;
    color: #ffffff;
  }

  svg {
    color: #ffffff;
    font-size: 24px;
  }
}

.mobile-layout {
  position: relative;
  // width: 100%;
  // height: 100%;
  margin: 0 auto;
  // min-height: 100vh;
  // overflow: hidden;
  // overflow-y: auto;
  background: #ffffff !important;
  // max-width: 500px;
  // padding-bottom: env(safe-area-inset-bottom);
  // padding-top: env(safe-area-inset-top);
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.desktop-layout {
  position: relative;
  margin: 0 auto;
  background-color: #ffffff;

  .fund-detail-page {
    padding: 60px 0 80px;
    min-height: 100vh;
    height: 100%;

    .list-history-data {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 50px;

      .item {
        background: #000;
        width: calc(50% - 50px);
        border: none;

        .tx_inputbox {
          border: 1px solid #1da2b4 !important;
          background: transparent;
          padding: 0 11px;
        }

        .btn-fund-add {
          background: #FBC501 !important;
        }
      }
    }
  }
}

.acbox {
  margin: 40px 0;
  background: #000;
}

.acrecordTitle {
  padding: 15px 20px;
  color: #c7cce6;
  font-size: 16px;
}

.acrecordListTitle,
.acrecordListTxt,
.acrecordListTxt article {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.acrecordListTitle span,
.acrecordListTxt span {
  width: 100%;
  padding: 10px 20px;
  margin-right: 5px;
  color: #637085;
}

.acrecordListTxt {
  width: 100%;

  span {
    color: #ffffff;
  }
}

.acrecordListTitle span:last-child {
  margin-right: 0;
}

.acrecordListTxt article {
  margin-top: 10px;
  border-bottom: 1px solid #1e2c42;
}

.acrecordListTxt article span {
  text-align: center;
  padding: 10px 5px;
  margin-right: 5px;
  width: 100%;
}

.acrecordListTxt article span:last-child {
  margin-right: 0;
}

.acbuyList {
  position: relative;
  border-radius: 10px;
  padding: 20px;
  width: 48%;
  margin-right: 4%;
  opacity: 0.95;
  margin-bottom: 20px;
}

.acbuyList:nth-child(2n) {
  margin-right: 0;
}

.acbuyListTitle {
  display: flex;
  flex-direction: row;
  color: #01af90;
  font-size: 20px;
  font-weight: 700;
  align-items: center;
}

.acbuyListTitle img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.acbuyListfTitle {
  font-size: 14px;
  margin-top: 5px;
  color: #999;
}

.sharemore {
  margin: 20px 0 0;
  text-align: center;
  cursor: pointer;
}

.pd20 {
  padding: 20px 0;
}

.mobile-content {
  position: relative;
  z-index: 99;
}

.footer-mobile-top {
  position: fixed;
  z-index: 999;
  left: 50%;
  width: 100%;
  max-width: 540px;
  bottom: 47px;
  display: flex;
  justify-content: space-between;
  padding: 5px 30px;
  transform: translateX(-50%);
  border-top: 1px solid rgba(184, 184, 184, 0.53);
  color: #7f72a8;
  font-size: 15px;
  background: #ffffff;

  .data__balance {
    color: var(--theme-color);
  }
}

.footer-mobile {
  position: fixed;
  z-index: 999;
  background: #ffffff;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 540px;
  height: 47px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footer_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    color: rgb(146, 146, 146);
    text-transform: uppercase;
    min-width: 92px;

    &.main-img {
      top: -0.26667rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .img-mark {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 73px;
        filter: grayscale(0) opacity(1);
      }
    }

    &.active {
      img {
        filter: grayscale(0) opacity(1);
      }

      svg {
        color: var(--theme-color);
      }
    }

    svg {
      font-size: 16px;
      margin-bottom: 4px;
      color: #cccccc;
    }

    h5 {
      font-size: 13px;
      font-weight: 600;
      text-align: center;
    }

    img {
      filter: grayscale(0.7) opacity(0.6);
      width: 37px;
      height: auto;
    }
  }
}

.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }

  .loading-img {
    width: 120px;
  }
}

.btn {
  box-shadow: none;
  border: none;
  outline: 0;
  text-align: center;
  background-color: var(--theme-color);
  color: #ffffff;
  width: 100%;
  padding: 6px 11px;
  font-size: 14px;
  min-height: 38px;
  border-radius: 8px;

  &:hover,
  &:focus {
    box-shadow: none;
    border: none;
    outline: 0;
    opacity: 0.8;
  }
}

.btn-group {
  display: flex;
  justify-content: center;
}

.btn-more {
  margin: 0 auto;
  margin-top: 15px;
  font-size: 17px;
  font-weight: 600;
}

.form-action {
  background-color: #ffffff;
  padding: 0 20px;

  .form-item {
    padding: 15px 0;

    .form_label {
      flex: none;
      box-sizing: border-box;
      text-align: left;
      word-wrap: break-word;
      margin-right: 15px;
      font-size: 18px;
    }

    .form_field {
      width: 100%;
    }

    &.error {
      .field_control {
        border: 1px solid #f55f5f;
      }

      .error_t {
        display: block;
        color: #f55f5f;
        margin-top: 5px;
      }
    }
  }

  label {
    display: flex;
    align-items: center;

    span {
      color: #363636;
      font-size: 18px;
      margin-left: 10px;
    }
  }
}

.form-horizontal {
  .form-item {
    display: flex;
  }
}

.field_control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 4px 10px;
  color: #363636;
  line-height: inherit;
  text-align: left;
  background-color: #eaeff2;
  height: 36px;
  border: 0;
  outline: 0;
  resize: none;
  font-size: 14px;
  border-radius: 7px;
}

.control-number {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 10px;
  color: #363636;
  line-height: inherit;
  text-align: left;
  background-color: #eaeff2;
  border: 0;
  outline: 0;
  resize: none;
  font-size: 14px;
  border-radius: 7px;
  height: 36px;
}

.field_select {
  display: block;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 36px;
  padding: 4px 10px;
  color: #363636;
  line-height: inherit;
  text-align: left;
  background-color: #eaeff2;
  border: 0;
  outline: 0;
  resize: none;
  font-size: 14px;
  border-radius: 7px;
}

.control-wrapper {
  display: flex;
  align-items: center;
  background-color: #eaeff2;

  svg {
    color: var(--theme-color);
    font-size: 18px;
    margin-right: 6px;
  }
}

.radio_control {
  width: 20px;
  height: 20px;
}

.bank-img {
  width: 100%;
  margin-top: 5%;
}

.text-red {
  color: #eb3f21 !important;
}

.modal {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  font-family: Roboto;
  font-size: 18px;

  .mask {
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.603);
  }

  &.modal-welcome {
    .modal-content {
      border: none;
      background: transparent;
      width: 400px;

      img {
        margin-bottom: 0;
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  &.modal-full {
    .modal-content {
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      transform: none;
      border-radius: 0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      overflow: hidden;
      overflow-y: auto;

      .modal-header {
        background: #ffffff;

        h2 {
          font-size: 18px;
          color: #000;
        }

        .btn-close {
          font-size: 20px;
          color: #000;
          background: transparent;
        }
      }
    }

    .content {
      padding: 0 20px;

      p {
        text-align: left;

        b {
          color: #000;
        }
      }

      ul {
        padding-left: 15px;

        li {
          color: #000;
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
          list-style: disc;
          margin-bottom: 10px;
        }
      }
    }
  }

  &.modal-bet-game {
    .modal-content {
      padding: 15px;

      .classic-bet-now {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;

        p {
          text-align: center;
        }
      }
    }

    .table-info {
      background-color: #f8f8fb;
      padding: 0 4px;
    }

    h2 {
      font-size: 30px;
      font-weight: 900;
      text-align: center;
      margin: 15px 0;
    }
  }

  &.modal-bet {
    .modal-content {
      background-color: #ffffff;
      border-radius: 10px;
      overflow: hidden;
    }

    .modal-header {
      background-color: var(--bg-color);
      padding: 10px 15px;
      border-bottom: 1px solid #e6e6e6;

      h5 {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        color: #292929;
        width: 100%;
      }

      .btn-close {
        background: transparent;
        color: #5c5c5c;
        font-size: 20px;
        font-weight: 600;
        position: absolute;
        right: 15px;
        top: 5px;
      }
    }

    .modal-footer {
      border-top: 1px solid #e7e7e7;
      padding: 10px 0;
    }

    select {
      border: 1px solid #e7e7e7;
    }

    .input-wrapper {
      border: 1px solid #e7e7e7;

      input {
        font-size: 14px;
        height: 40px;
        padding: 4px 8px;
      }
    }

    .modal-body {
      padding: 10px;

      p {
        font-size: 12px;
        color: #363d47;
      }
    }

    .btn-group {
      justify-content: flex-end;

      .btn-save {
        text-transform: uppercase;
        padding: 4px 15px;
        font-size: 14px;
      }

      .btn-cancel {
        padding: 4px 15px;
        text-transform: uppercase;
        font-size: 14px;
      }
    }

    .info-table {
      border-radius: 4px;

      p {
        color: #718ba7;
        margin-bottom: 2px;
      }

      .item {
        display: flex;
        justify-content: space-between;
        padding: 2px 0;

        span {
          font-size: 12px;
          color: #838383;

          b {
            font-size: 14px;
          }

          &:first-child {
            color: #838383;
          }
        }
      }

      .input-number {
        height: 40px;
        border: none;
        outline: 0;
        width: 100%;
        color: #515151;
        border-radius: 6px;
        font-size: 14px;
        background-color: #e2e7ec;
      }
    }

    .list-bet {
      margin-top: 5px;
      overflow: hidden;
      overflow-x: visible;
      white-space: nowrap;
      width: 100%;
      gap: 10px;

      .item {
        width: 83px;
        height: 65px;
        border-radius: 10px;
        overflow: hidden;
        display: inline-block;
        margin-right: 15px;
        background: #fbbc01;

        &.active {
          border: 1px solid #caa015;

          p {
            color: #ffffff;
            background-color: #3487aa;

            &:last-child {
              background-color: #3487aa;
            }
          }
        }

        p {
          width: 100%;
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 13px;

          &:last-child {
            background-color: #72b3cf;
          }
        }
      }
    }

    .btn-confirm {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fbbc01;
      color: #ffffff;
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      padding: 12px 15px;
      margin-top: 30px;
      border-radius: 10px;
    }

    .item-row {
      display: flex;
      justify-content: space-between;
      padding: 2px 0;

      span {
        font-size: 12px;
        color: #505966;
      }
    }
  }

  .text-black {
    color: #292929 !important;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(24, 24, 24);
    padding: 10px 15px;
  }

  .modal-content {
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
    width: 100%;
    max-width: 36rem;
  }

  .modal-bottom {
    left: 0;
    bottom: 0;
    top: initial;
    width: 100%;
    transform: translateX(0);
    border-radius: 15px 15px 0 0;
  }

  .submit {
    display: flex;
    width: 100%;

    >button {
      flex: 1;
    }
  }

  .btn-close {
    background-color: #363636;
  }
}

@media screen and (max-width: 500px) {
  .modal.modal-welcome .modal-content {
    width: 280px;
  }
}

.collapses {
  .collapse-item {
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 18px;

    .collapse-title {
      padding: 15px 20px;
      border-bottom: 1px solid #e4e4e4;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--theme-color);

      .van-icon {
        font-size: 20px;
        color: #979797;
      }
    }

    .collapse-wrapper {
      padding: 15px 20px;
      transition: height 0.2s ease-in-out;
      will-change: height;

      .collapse-body {
        padding-left: 20px;
        color: var(--theme-color);

        p {
          color: var(--theme-color);
        }
      }
    }
  }
}

.nodata {
  .noMSG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #969799;

    img {
      max-width: 80px;
      width: 100%;
    }
  }
}

.line {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
}

.remix-sidebar {
  z-index: 999;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  overflow-y: auto;

  .menu-header {
    padding: 60px 20px 20px;
    background-color: #013f83;
    position: relative;

    .btn-close {
      position: absolute;
      z-index: 1;
      right: 20px;
      top: 30px;
      font-size: 32px;
      color: #ffffff;
    }

    h5 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
    }
  }

  .menu-list {
    background-color: var(--bg-color);

    .button-logout {
      color: #fff;
      background-color: #007aff;
      font-size: 14px;
      text-align: center;
      width: 80%;
      padding: 8px 15px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }

    ul {
      li {

        a,
        .menu-item {
          display: flex;
          justify-content: space-between;
          padding: 15px 20px;
          color: #e0e0e0;
          border-bottom: 1px solid #003874;

          &.diver {
            border-bottom: 2px solid #004b9b;
          }

          .left {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            img {
              filter: brightness(0) invert(1);
              width: 24px;
              height: 24px;
            }
          }

          .right {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }
        }
      }
    }
  }
}

.mask {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #36363669;
}

@media screen and (max-width: 450px) {
  .footer-mobile .footer_item {
    min-width: 85px;

    h5 {
      font-size: 12px;
    }
  }
}

#highcharts iframe {
  height: calc(100vh - 360px);
}

.theme-color {
  color: var(--theme-color) !important;
}

.update-info-container,
.information-container,
.setting-container {
  min-height: 82vh;
  background-color: #ffffff;
}

// Update 08/27/2023
html,
body {
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;
  font-family: BinancePlex, Arial, sans-serif !important;
  font-size: 14px;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #424852;
  font-size: 14px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #424852;
  font-size: 14px;
}

.fe6im {
  color: #1b1b1b !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #424852;
  font-size: 14px;
}

input:focus {
  background: #1b1e25;
  outline: 1px solid #1b1e25;
}

select:focus {
  background: #1b1e25;
  outline: 1px solid #1b1e25;
}

textarea:focus {
  background: #1b1e25;
  outline: 1px solid #1b1e25;
}

.fl {
  float: left;
}

/*左浮动*/
.fr {
  float: right;
}

/*右浮动*/
.bhalf {
  width: 50%;
}

/*div50%的宽度*/
.allhg {
  height: 100%;
}

/*继承父元素100%的高度*/
.txtl {
  text-align: left;
}

.txtr {
  text-align: right;
}

.fcy {
  color: #fcd535;
}

/*黄色*/
.fcc {
  color: #707a8a;
}

/*灰色*/
.fch {
  color: #000;
}

/*黑色*/
.fzm {
  font-size: 12px;
}

.fzmm {
  font-size: 14px;
}

.fzmmm {
  font-size: 16px;
}

.fw {
  font-weight: bold;
}

.fe6 {
  color: #e6e6e6;
}

.fa8a {
  color: #707a8a;
}

.bgf5465c {
  background: #f5465c;
}

.bgf5465cim {
  background: #f5465c !important;
}

.f121420im {
  color: #121420 !important;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: #00b897;
  overflow: hidden;
}

.alltn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  background: #fcd535;
}

.allbtn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 5px;
  background: #fbbc01;
  color: #fff;
  margin-top: 20px;
}

.spot-account {
  .mu-card {
    background: #fbbc01 !important;
    color: #fff !important;
    position: relative;
    border-radius: 2px;
    width: 100%;
    border-radius: 15px;
    max-width: 375px;
    margin: 0px auto 30px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

    .mu-card-title-container {
      padding: 16px;
      position: relative;

      .mu-card-title {
        font-size: 24px;
        color: #ffffff;
        line-height: 36px;
      }

      .mu-card-sub-title {
        font-size: 14px;
        color: #ffffff;
        display: block;
      }
    }

    .mu-card-actions {
      padding: 8px;
      position: relative;
    }
  }
}

.flexTab {
  display: flex;
  justify-content: space-between;
}

.header_box {
  .tabs {
    border-radius: 20px;
    padding: 0;
    overflow: hidden;

    .tab {
      min-width: 110px;
      color: #888;
      font-size: 16px;
      height: 32px;
      line-height: 32px;

      &.active {
        background: #fbbc01;
        color: #ffffff;

        &::after {
          display: none;
        }
      }
    }
  }
}

.home-page {
  .search-container {
    width: 100%;
    border-radius: 20px;
    background: #f6f6f6;

    input {
      width: 100%;
      font-size: 16px;

      &::placeholder {
        font-size: 16px;
        color: #bdbdbd;
      }
    }

    svg {
      color: #bdbdbd;
      font-size: 20px;
    }
  }
}

.quickly-buy {
  background-image: url(../img/icon/icon31.png);
  background-size: 100% 100%;
  height: 78px;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
}

.certify-box {
  background-image: url(../img/icon/icon32.png);
  background-size: 100% 100%;
  height: 78px;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
}

.tabs.tab-bin {
  border: none;

  .tab {
    font-size: 16px;
    color: #888;
    position: relative;
    border: none;
    flex: none;
    cursor: pointer;

    span {
      position: relative;
      cursor: pointer;
    }

    &.active {
      background: transparent;
      color: #fbac01;

      span {
        &:after {
          content: "";
          width: 100%;
          height: 15px;
          position: absolute;
          left: 50%;
          bottom: -10px;
          transform: translateX(-50%);
          background-color: #ffca1c;
          opacity: 0.1;
        }
      }
    }
  }
}

.regist-now {
  width: 100%;
  height: 286px;
  padding-top: 90px;
  box-sizing: border-box;
  background-image: url(../img/banner/regist-bg.png);
  background-size: 100% 100%;

  h3 {
    font-size: 28px;
    text-align: center;
  }

  h4 {
    font-size: 18px;
    text-align: center;
  }
}