.desktop-layout {
  .logo {
    margin-bottom: 3px;

    img {
      width: 65px;
      height: 70px;
    }
  }
}

.btn-game-bet {
  // background-color: #383838 !important;
  font-size: 16px;
  position: fixed;
  z-index: 99999;
  left: 110px;
  top: 15px;
  margin: 0;
  width: 130px;
}

@media screen and (max-width: 768px) {
  .btn-game-bet {
    left: initial;
    right: 80px;
    top: 10px;
  }
}

.notice {
  display: flex;
  height: 40px;
  margin-top: 5px;
  padding: 0px 10px;

  .left {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 5px 10px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 20px;
      height: auto;
    }

    a {
      img {
        width: 30px;
        height: auto;
      }
    }

    svg {
      color: #8897ad;
      font-size: 14px;
    }

    span {
      font-size: 14px;
      color: rgb(0, 0, 0) !important;
      margin-left: 10px;
      flex: 1.5 1;
    }
  }
}

.fast-banner {
  padding: 10px 15px 0 15px;
  margin: 0 auto;
  color: #fff;
  position: relative;

  .fast-text {
    position: absolute;
    left: 24px;
    top: 55%;
    font-size: 13px;
    transform: translateY(-50%);
  }
}

.title-home {
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 600;
}

.top-coin {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: calc(33% - 8px);
    color: var(--text-color);
    border-radius: 8px;
    padding: 10px;

    h5 {
      font-size: 14px;
      color: #707a8a;
    }

    h4 {
      font-size: 22px;
      font-weight: 500;
      color: #e6e6e6;
    }

    p {
      font-size: 16px;
      color: #0ecb81;
    }
  }
}

.quick-action {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 10px;
  margin: 10px 0;
  background-color: var(--bg-color);

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(50% - 8px);
    color: var(--text-color);
    height: 80px;
    border-radius: 5px;

    img {
      width: 25px;
      height: 25px;
      object-fit: contain;
    }

    p {
      text-align: center;
      font-size: 12px;
      color: #353535;
      margin-top: 5px;
      font-weight: 500;
    }
  }
}

.service {
  margin-top: 10px;
  padding: 0 10px;
  display: flex;
  box-sizing: border-box;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-color);
    color: var(--text-color);
    height: 75px;
    width: 50%;
    padding: 10px 20px;

    img {
      width: 25px;
      height: auto;
    }

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      padding: 8px;
    }
  }
}

.quick-buy {
  margin-top: 10px;
  padding: 0 10px;

  .wrapper {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    color: var(--text-color);
    gap: 10px;
    width: 100%;

    .col1 {
      flex: 1;
    }

    .col2 {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .item {
        justify-content: flex-start;
        gap: 10px;

        p {
          font-weight: 500;
          font-size: 13px;
          color: #1b1b1b;
        }

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .item {
      color: var(--text-color);
      background-color: var(--bg-color);
      border-radius: 6px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      height: auto;
      padding: 10px;

      img {
        height: 80px;
        width: auto;
        object-fit: contain;
        opacity: 0.8;
      }
    }
  }

  h5 {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #1b1b1b;
  }

  p {
    color: #1b1b1b;
    line-height: 15px;
  }

  img {
    width: 100px;
  }
}

.banner-notice {
  margin-top: 10px;
  padding: 10px 10px 0;
  background-color: var(--bg-color);

  .item {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    width: 99%;
    height: 99px;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;

    h5 {
      position: absolute;
      left: 15px;
      top: 10px;
      font-size: 28px;
      font-weight: 600;
    }

    p {
      position: absolute;
      left: 15px;
      top: 50px;
      font-size: 14px;
    }
  }
}

.market-page {
  padding: 20px 15px 80px;

  .coin-list {
    .list {
      .item {
        height: 55px;
        padding: 0 10px;

        .col1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          img {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
}

.coin-list {
  p {
    font-size: 16px;
  }

  .list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 55px;
      color: #888;
      font-size: 16px;
      padding: 0 10px;
      border-bottom: 1px solid #f6f6f6;

      &.tab {
        color: #5a5a5a;

        .active {
          color: #0052cc;
        }

        .col1 {
          justify-content: center;
          font-size: 14px;
          flex: 1;
        }

        .col2 {
          justify-content: center;
          font-size: 14px;
          flex: 1;
        }
      }

      &.header {
        font-size: 12px;
        font-weight: 400;
        height: 50px;
        width: 100%;
        max-width: 500px;
        padding: 0 15px;
        color: #888;
        background: #ffffff;
      }

      .col1 {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 39%;

        .name {
          line-height: 18px;
          color: #000000;
          font-weight: 600;
        }

        .help {
          font-size: 13px;
          color: #5a5a5a;
          font-weight: 400;
          line-height: 16px;
        }

        b {
          font-size: 15px;
          font-weight: 600;
          line-height: 14px;
        }

        span {
          font-size: 12px;
          font-weight: 400;
          color: #5a5a5a;
          line-height: 14px;
        }
      }

      .col2 {
        display: flex;
        justify-content: center;
        flex: 1;
        font-size: 14px;
      }

      .col3 {
        display: flex;
        justify-content: flex-end;
        flex: 1;
      }
    }

    .price {
      font-weight: 500;
      font-size: 16px;
    }

    .tag {
      border: 2px;
      width: 70px;
      height: 35px;
      line-height: 35px;
      font-size: 13px;
      color: #3db485;
      text-align: center;
      border-radius: 3px;
      background: #1e363a;
      border-radius: 5px;

      &.green {
        background-color: #3db485;
        color: #fff;
      }

      &.red {
        background-color: #ff494a;
        color: #fff;
      }

      &.blue {
        background-color: #00abff;
        color: #fff;
      }
    }
  }
}

.text-green {
  color: #fbbc01 !important;
}

.bg-green-800 {
  background-color: var(--bg-color) !important;
}

#general {
  padding-bottom: 80px;

  .card-header {
    background-color: #3677d8;
    color: #ffffff;
    box-shadow: none;
    border: none;
    outline: 0;
    text-align: center;
    position: relative;
    overflow: initial;
    margin-bottom: 75px;
    background-image: url("../img/banner/banner_wallet.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .sbui-card-content {
      padding: 3rem 2rem;
    }
  }

  .list-token {
    padding: 0 10px;

    .sbui-card {
      border-radius: 6px;
      margin-bottom: 10px;
    }
  }

  .sbui-card {
    box-shadow: none;
    border: none;
  }

  .quick-action {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 5px 10px;
    margin: 10px 0;
    background-color: #ffffff;
    border-radius: 15px 15px 0 0;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(33% - 8px);
      color: var(--text-color);
      height: 80px;
      border-radius: 5px;

      img {
        width: 40px;
        height: auto;
      }

      p {
        color: #4e4e4e;
        font-weight: 400;
      }
    }
  }
}

#withdraw {
  .card {
    background-color: #f7f6fb;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.input-exchange {
  display: flex;
  align-items: center;
  gap: 10px;

  .sbui-input {
    width: calc(100% - 150px);
    border: none;
    box-shadow: none;
    outline: 0;
    font-size: 16px;
    padding: 4px 0;
  }

  .btn-all {
    background: transparent;
    color: rgb(0, 122, 255);
    font-size: 16px;
    width: 100px;
    padding: 0;
  }
}

.exchange-info {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 5px;

  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 auto;
    gap: 6px;
    width: calc(33% - 5px);
    font-size: 12px;
    color: #303133;
  }
}

.list-coin-deposit {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  .item {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    background: #1b1d2a;
    padding: 0px 5px;
    border-radius: 10px;
    text-align: center;
    display: flex;

    &:hover {
      background-color: #8897ad;
      color: #ff9100;
    }

    .left {
      width: 30%;
      height: 40px;
      line-height: 40px;
      float: left;
      text-align: left;
      padding-left: 20px;

      span {
        font-size: 14px;
        color: #707a8a;
      }
    }

    .center {
      width: 30%;
      height: 40px;
      line-height: 40px;
      float: left;

      span {
        font-size: 14px;
        color: #707a8a;
      }
    }

    .right {
      width: 40%;
      height: 40px;
      line-height: 40px;
      float: right;
      text-align: right;
    }

    svg {
      color: #7c7c7c;
      font-size: 14px;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
}

.classic-bet-now {
  .content {
    width: 100%;
  }

  .count-time {
    font-size: 50px;
    font-weight: 600;
    color: #e67b00;
    text-shadow: 2px 2px 3px #bbbbbb;
  }

  .table-info {
    width: 100%;

    .item {
      display: flex;

      span {
        flex: 1;
        padding: 5px;
        font-size: 14px;
        color: #4a7697;
        text-align: left;

        &:first-child {
          font-weight: 600;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

.cskh-iframe {
  height: calc(100vh - 120px);
}

.cskh-icon {
  position: fixed;
  right: 15px;
  bottom: 80px;
  z-index: 999;
  width: 50px;
  height: 50px;
  background: #1c8bf3;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 28px;
    filter: invert(100%) sepia(0%) saturate(3447%) hue-rotate(41deg) brightness(2000%);
  }
}

.btn-download {
  background: transparent;
  width: 150px;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
    height: auto;
  }
}

.tab-head {
  position: relative;

  &.tabs {
    width: 100%;

    .tab {
      flex: 1;
    }
  }
}

.search-container {
  display: flex;
  align-items: center;
  background: #f7f6fb;
  border-radius: 5px;
  width: 220px;
  padding: 0 15px;
  height: 35px;
  margin: 10px 0;

  input {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    height: 35px;
    color: #0b1622;
    font-size: 14px;

    &:focus {
      border: none;
      outline: 0;
      box-shadow: none;
    }
  }

  svg {
    font-size: 14px;
  }
}

.btn-register {
  background: #0a0d11;
  border: none;
  outline: 0;
  font-weight: 400;
}

.text-primary {
  color: #0052cc;
}

#logo {
  height: 120px;
  width: auto;
  margin: 0 auto;
}

.auth-page {
  overflow: hidden;
  overflow-y: auto;
  padding-top: 10%;

  .sbui-sidepanel {
    height: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.maintenance-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-wrapper {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 45px;
    font-size: 45px;
    color: #fffb00;
    z-index: 999;
  }
}

.trade-page {
  background-color: #131a2e;
  padding-top: 44px;
  padding-bottom: 150px;
  box-sizing: border-box;

  .bg_header {
    background: #131a2e;
    color: #fff;
    border: none;
  }

  &.trade-detail-page {}

  &.light {
    background-color: #ffffff;

    .price-info .col2>div h5 {
      color: #0b1622;
    }

    .action-group .btn-history {
      color: #0b1622;
    }

    .action-group .coin {
      color: #2f4053;

      b {
        color: #0b1622;
      }
    }

    .chart-time .btn {
      border: none;
      height: 40px;
      color: #535353;
      font-size: 12px;
      flex: 1;

      &.active {
        color: #2196f3;
        border-bottom: 3px solid #2196f3;
      }
    }

    .trade-header .tabs .tab {
      color: #0b1622;

      &.active {
        background-color: #d3d3d3;
        color: #0b1622;
      }
    }

    .trade-footer {
      background-color: #ffffff;
      color: #0b1622;

      .sbui-checkbox__label-container__label {
        color: #0b1622;
      }
    }
  }

  .trade-header {
    .tabs {
      background: transparent;
      justify-content: center;

      .tab {
        border: 1px solid #c7c7c7;
        color: #c7c7c7;
        font-size: 12px;
        padding: 5px 10px;

        &.active {
          color: #ffffff;
          background-color: #bdbdbd;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  .action-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;

    .coin {
      color: #bdbdbd;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 18px;
      font-weight: 400;

      b {
        color: #e9e9e9;
        font-weight: 600;
      }

      svg {
        color: #b4b4b4;
      }
    }

    .btn-theme {
      width: 25px;
      height: 25px;
      min-height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      svg {
        font-size: 14px;
      }

      &.light {
        background-color: #ffffff;
        color: #0b1622;
      }

      &.dark {
        background-color: #0b1622;
        color: #ffffff;
      }
    }

    .btn-history {
      background: transparent;
      color: #e9e9e9;
      font-size: 13px;
      display: flex;
      align-items: center;
      gap: 5px;
      width: auto;
      font-weight: 400;
      min-height: 25px;
    }
  }

  .price-info {
    display: flex;
    padding: 0 20px;

    .col1 {
      width: 45%;

      p {
        font-size: 13px;
        color: #62768a;
        line-height: 24px;
      }

      h5 {
        font-size: 16px;
        line-height: 24px;
        color: #0ecb81;
      }

      h4 {
        font-size: 22px;
        line-height: 35px;
        font-weight: 500;
        color: #0ecb81;
      }

      .text-green {
        color: #32c286 !important;
      }

      .text-red {
        color: #e6495c !important;
      }
    }

    .col2 {
      width: 55%;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      >div {
        width: calc(50% - 5px);

        p {
          font-size: 13px;
          color: #62768a;
        }

        h5 {
          font-size: 14px;
          color: #d4d4d4;
        }
      }
    }
  }

  .chart-time {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    width: 100%;
    box-sizing: border-box;

    .btn {
      background: transparent;
      min-height: 32px;
      height: 32px;
      width: unset;
      border-radius: 4px;
      color: #e0e0e0;
      padding: 2px;

      &.active {
        border-bottom: 2px solid #fcd535;
        border-radius: 0;
      }
    }
  }
}

.trade-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 60px;
  transform: translateX(-50%);
  max-width: 500px;
  background-color: #222430;
  padding: 10px 15px;
  border-radius: 20px 20px 0px 0px;

  .sbui-checkbox-container {
    align-items: center;
  }

  .sbui-checkbox-container--medium .sbui-checkbox {
    margin-right: 6px;
    height: 20px;
    width: 20px;
  }

  .sbui-checkbox__label-container {
    line-height: 12px;
  }

  .sbui-checkbox__label-container__label {
    color: #ffffff;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 13px !important;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .btn-buy {
    border: none;
    box-shadow: none;
    outline: 0;
    background: #1ab184;
    color: #ffffff;
    border-radius: 10px;
    padding: 0 15px;
    width: 45%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  .btn-sell {
    border: none;
    box-shadow: none;
    outline: 0;
    background: #fa6767;
    color: #ffffff;
    border-radius: 10px;
    padding: 0 15px;
    width: 45%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}

.mu-card-media {
  width: calc(100% - 32px);
  margin: 0 auto;
  overflow: hidden;
  border-radius: 5px;
  position: relative;

  .mu-card-media-title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    background-color: rgba(0, 0, 0, 0.54);
    padding: 8px 16px;

    .mu-card-title {
      font-size: 24px;
      color: hsla(0, 0%, 100%, 0.87);
      line-height: 36px;
    }

    .mu-card-sub-title {
      color: hsla(0, 0%, 100%, 0.54);
      font-size: 14px;
    }
  }
}

.mu-card-header {
  padding: 16px;
  font-weight: 500;
  position: relative;
  white-space: nowrap;

  .mu-avatar {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-right: 16px;
    background-color: #fbbc01;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    display: inline-block;
  }

  .mu-card-header-title {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    padding-right: 90px;
  }

  .mu-card-sub-title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.57);
  }

  .mu-card-right {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 22px;
  }
}

.member-page {
  .mu-card {
    width: 100%;
    background: #f0b000;
    color: #ffff;
    height: 230px;

    .mu-card-body {
      padding: 32px 16px;

      h1 {
        font-size: 22px;
        font-weight: 600;
      }
    }
  }

  .container_main {
    position: relative;
    margin-top: -70px;
    z-index: 99;
  }

  .lbox {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 3%;
    background: #ffffff;
    margin: 0;
    border-radius: 0;

    .lbox_l {
      width: 50%;
      height: 50px;
      line-height: 50px;
      float: left;
      text-align: left;
    }

    .lbox_r {
      width: 50%;
      height: 50px;
      line-height: 50px;
      float: right;
      text-align: right;
    }
  }
}

.member-header {
  color: #ffffff;
  box-shadow: none;
  border: none;
  outline: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: initial;
  height: 300px;
  background-image: url("../img/banner/bg_signin_top.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  .btn-setting {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #eaecee;
    font-size: 18px;
  }

  .avatar {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .username {
    font-size: 18px;
    font-weight: 500;
    color: #ececec;
    margin-bottom: 5px;
  }

  .ref {
    line-height: 16px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 15px;
    padding: 5px 10px;
    width: 100px;
    background-color: #c5dcff;
    color: #3a76cf;
    font-weight: 400;
    text-align: center;
    margin-left: 10px;
  }
}

.memberMenu {
  margin: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  z-index: 10;

  li {
    padding: 12px;
    border-bottom: 1px solid #f3f3f3;

    a,
    .link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgba(27, 35, 51, 0.9);

      >div {
        display: flex;
        align-items: center;
      }

      svg {
        font-size: 20px;
        width: 16px;
        color: rgba(76, 94, 109, 0.6);
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      span {
        margin-left: 12px;
        font-size: 13px;
      }

      .angle-right {
        font-size: 12px;
        color: rgba(76, 94, 109, 0.6);
      }
    }
  }
}

.btn-logout {
  display: flex;
  padding: 10px;
  font-size: 16px;
  margin: 0 auto;
  border-radius: 25px;
  min-width: 200px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.qty-input {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e7e7e7;

  .btn {
    width: 40px;
    min-height: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: #797979;
    border-radius: 0;
    font-size: 14px;
    text-transform: uppercase;
  }

  .sbui-input {
    background-color: #ffffff;
    border: none;
    border-bottom: transparent;
    color: #000000;
    height: 40px;
    border-radius: 0;
    padding: 0;
  }
}

.feature-page {
  position: relative;
  padding: 25px 15px 80px;

  .list-history-data {
    padding: 15px 0;
  }

  .feature-header {
    .action-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .coin {
        p {
          color: #bbbbbb;
          font-size: 12px;

          b {
            font-size: 22px;
            color: #e9e9e9;
          }

          .price {
            margin-left: 5px;
            font-size: 22px;
          }
        }

        h5 {
          margin-top: 5px;
          font-size: 14px;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }

    .btn-history {
      background: #5a6770;
      color: #e5ecf5;
      font-size: 12px;
      border-radius: 0;
      padding: 4px 10px;
      width: auto;
      min-height: 25px;
      height: 25px;
    }
  }

  .feature-board {
    font-size: 11px;
    padding-right: 5px;

    .info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      color: #cccccc;
    }

    .group-action {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .btn {
        width: 48%;
        height: 36px;
        float: left;
        text-align: center;
        padding: 0 !important;
        color: #242424;
        font-weight: 600;
        background: transparent;
        text-transform: uppercase;
        border-radius: 5px !important;

        &.btn-buy {
          &.active {
            color: #3db485;
            border: none;
          }
        }

        &.btn-sell {
          &.active {
            color: #fa6767;
            border: none;
          }
        }
      }
    }

    select {
      background-color: #ffffff;
      border-top: none;
      border: 1px solid #e7e7e7;
      color: #000000;
      height: 42px;
      border-radius: 0;
    }

    .quantity {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-bottom: 5px;

      .btn {
        flex: 1;
        width: auto;
        min-width: calc(20% - 5px);
        max-width: calc(25% - 5px);
        min-height: 28px;
        height: 28px;
        padding: 5px;
        font-size: 12px;
        border-radius: 4px;
        background: transparent;
        border: 1px solid #1e2630;
        color: #cccccc;

        &.active {
          background-color: #293750;
          color: #d3dce9;
          border: none;
        }
      }
    }

    .qty-info {
      display: flex;
      gap: 5px;
      color: #cccccc;

      span {
        &:first-child {
          width: 50px;
        }
      }

      .show {
        background-color: #293750;
        padding: 5px;
        width: calc(100% - 50px);
        text-align: center;
        height: 25px;
        border-radius: 4px;
      }
    }
  }

  .contract-board {
    .qty-input {
      background-color: #f6f6f6;

      .sbui-input {
        height: 48px;
        background-color: #f6f6f6;
        font-size: 16px;
        font-weight: 600;

        &::placeholder {
          font-weight: 600;
          font-size: 16px;
          color: #979797;
        }
      }
    }

    .list-time {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(3, 1fr);

      .item {
        background: #f6f6f6;
        color: #888;
        height: 40px;
        border-radius: 6px;
        text-align: center;

        &.active {
          color: #fff;
          background-color: #fbbc01;

          h5 {
            color: #fff;
          }

          p {
            color: #fff;
          }
        }

        h5 {
          font-size: 15px;
          font-weight: 600;
          text-align: center;
          width: 100%;
          color: #888;
        }

        p {
          font-size: 13px;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }

  h5 {
    color: #2c2c2c;
    width: 50px;
  }

  h4 {
    font-size: 16px;
    color: #2c2c2c;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .diver {
    background: #24282c;
  }

  .price-history {
    padding-left: 5px;

    .item {
      height: 25px;
      width: 100%;
      position: relative;

      .wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 99;
      }

      .inner {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        height: 36px;

        span {
          color: #a7a7a7;
          font-size: 13px;
          margin-left: 3px;
        }

        .price {
          font-size: 16px;
          color: #fbbc01;
        }
      }

      &.buy {
        .line {
          background-color: #def2f3;
        }
      }

      &.sell {
        .line {
          background-color: #fee5e8;
        }
      }

      .line {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 0;
        border: none;
        margin: 0;
      }

      p {
        color: #dddddd;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .trade-history {
    margin-top: 10px;
    min-height: 350px;

    .tabs {
      background: transparent;
      padding: 0;
      border-bottom: 1px solid #9b9b9b;

      .tab {
        color: #6f7072;
        font-size: 11px;
        padding: 5px 8px;
        flex: 1 auto;

        &.active {
          color: #667dff;

          &::after {
            display: none;
            background-color: #cccccc;
          }
        }
      }
    }

    .table-data {
      color: #dddddd;
      font-size: 11px;
      font-weight: 400;

      .text-left {
        text-align: left;
      }

      .text-right {
        text-align: right;
      }

      th {
        td {
          padding: 5px;
          font-weight: 400;
          vertical-align: top;
        }
      }

      tbody {
        min-height: 350px;
      }
    }
  }

  .noRecord {
    color: #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    svg {
      font-size: 40px;
    }
  }
}

.diver {
  width: 100%;
  height: 1px;
  background: #e9e9e9;
  margin: 8px 0;
}

.spot-page {
  .feature-header {
    .action-group {
      .coin {
        p {
          font-size: 16px;

          span {
            margin-right: 5px;
          }

          b {
            font-size: 16px;
            color: #e9e9e9;
          }
        }
      }
    }
  }

  .feature-board {
    .group-action {
      .btn {
        border-radius: 0;
      }
    }

    .type-select {
      select {
        color: #e9e9e9;
        background-color: #293750;
        border: none;
      }
    }

    p {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .button-footer {
    display: flex;
    align-items: center;
    gap: 20px;

    .btn-buy {
      border-radius: 5px;
      background-color: #3db485;
      color: #ffffff;
      border: none;
      width: 50%;
      height: 40px;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-sell {
      border-radius: 5px;
      background-color: #fa6767;
      color: #ffffff;
      border: none;
      width: 50%;
      height: 40px;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .price {
    font-size: 18px;
    margin: 5px 0;
    color: #e9e9e9;
  }
}

.quantity-type {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
  margin-top: 15px;

  .btn {
    position: relative;
    flex: 1;
    width: auto;
    min-width: calc(20% - 5px);
    max-width: calc(25% - 5px);
    min-height: 28px;
    height: 28px;
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
    background: transparent;
    border: 1px solid #e9e9e9;
    outline: 0;
    color: #768094;

    &:hover,
    &:focus {
      box-shadow: none;
      border: none;
      outline: 0;
      background: transparent;
      opacity: 1;
    }

    &.active {
      border: 1px solid #0ecb81;
      color: #0ecb81;
    }

    .blbox_3 {
      width: 100%;
      height: 10px;
      background: #212332;
      border-radius: 5px;
    }
  }
}

.desktop-layout {
  .quantity-type .btn {
    border-color: #323232;
  }

  .btn-contract {
    padding: 2px 4px;
    min-height: 28px;
    background-color: #323232;
    color: #c7c7c7;
  }
}

.page-deposit {
  min-height: 130vh;
  height: 100%;
  width: 100%;

  .card {
    background: var(--bg-color);
    padding: 15px;
    border-radius: 10px;

    p {
      font-size: 14px;
    }
  }
}

.list-partner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

  a {
    img {
      width: 100%;
    }
  }
}

.user-info {
  padding-top: 45px;
  padding-bottom: 60px;

  .btn-logout {
    width: 85%;
    border-radius: 25px;
    margin-top: 10px;
  }

  .memberForm {
    background-color: #ffffff;
    margin-bottom: 10px;
  }

  .memberMenu {
    padding: 0;
    margin: 0;

    li {
      padding: 18px 12px;
    }
  }
}

.introduce-page {
  padding-bottom: 80px;
}

.language-page {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .item {
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #c8c7cc;

    svg {
      font-size: 30px;
      color: #999;
    }

    span {
      color: #333;
      font-size: 14px;
    }
  }
}

.list-lang {
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  .item {
    height: 60px;
    padding: 0;
    display: flex;
    align-items: flex-end;
    gap: 20px;
    border-bottom: 1px solid #333;
    justify-content: space-between;

    &.active {
      border-bottom: 1px solid #333;
    }

    svg {
      font-size: 30px;
      color: #999;
    }

    span {
      color: #707a8a;
      font-size: 16px;
    }
  }
}

.list-questions {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .item {
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 20px;

    svg {
      font-size: 30px;
      color: #999;
    }

    span {
      color: #333;
      font-size: 14px;
    }
  }
}

.page-history {
  tr {
    background: #ffffff;
  }
}

.content-questions {
  padding: 10px;
  font-size: 14px;
  color: #1e2630;
}

.card-info {
  p {
    color: #1e2630;
    margin-bottom: 5px;
  }
}

.text-blue {
  color: #1c8bf3 !important;
}

.fund-page {
  background-image: url("../img/bg-fund.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 90px;

  .sbui-card {
    border-radius: 15px;
  }

  h3 {
    color: #ffffff;
    font-size: 22px;
    margin-bottom: 5px;
  }

  h5 {
    color: #ffffff;
    font-size: 14px;
  }

  .col1 {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 0 10px;
    margin-bottom: 10px;

    p {
      flex: 1 auto;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      img {
        width: 43px;
        height: auto;
        margin-bottom: 5px;
      }

      b {
        font-size: 17px;
      }

      span {
        font-size: 13px;
      }
    }
  }
}

.btn-fund {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
  z-index: 99;
  background-color: #24acd4;
  color: #fff;
  width: 95%;
  padding: 10px 0;
  font-size: 18px;
}

.fund-detail-page {
  padding: 60px 0 80px;

  .header-card {
    background-color: #3677d8;
    color: #ffffff;
    box-shadow: none;
    border: none;
    outline: 0;
    text-align: center;
    position: relative;
    overflow: initial;
    background-image: url("../img/banner/banner_wallet.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0 10px !important;

    .head-pro-title {
      height: 133px;
      padding: 0 1rem;
    }

    .info-card {
      background-color: hsla(0, 0%, 100%, 0.2);
      -webkit-border-radius: 0;
      border-radius: 0;
      color: #fff;
    }

    .btn-history {
      position: fixed;
      right: -10px;
      top: 80px;
      width: 110px;
      min-height: 24px;
      -webkit-border-radius: 50px;
      border-radius: 50px;
      z-index: 99;
      text-align: center;
      background: #d5e7ff;
      color: #5a8af8;
      padding: 5px 10px;
      width: 120px;
      margin: 10px auto;
      font-size: 16px;
    }
  }
}

.fund-history {
  padding: 50px 0 80px;
}

.list-history-data {
  padding: 10px;
  width: 100%;

  .item {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    padding-bottom: 15px;
    border-bottom: 1px solid #f6f6f6;

    img {
      width: 42px;
      height: 42px;
    }

    .btn-fund-add {
      background: #fbc501;
      color: #fff;
      border: 0;
      font-size: 18px;
      padding: 4px 11px;
      height: 45px;
      width: 100%;
      min-height: 36px;
      border-radius: 8px;
      text-align: center;
      justify-content: center;
    }
  }

  .btn-bet-game {
    padding: 5px 11px;
    color: #ffffff;
    width: 80px;
    border-radius: 20px;
    text-align: center;
    font-size: 13px;
    height: 28px;

    &.buy {
      background-color: #00a876;
    }

    &.sell {
      background-color: #bb0000;
    }
  }
}

.text-blue-500 {
  color: #00abff !important;
}

.text-yellow-500 {
  color: #f2c94c !important;
}

.fund-detail {
  height: 100vh;
  width: 100%;
  background-color: #ffffff;
  color: #888;
  padding: 50px 15px 80px;

  .fund-head {
    padding: 1rem 0;

    img {
      width: 25px;
      height: 35px;
    }
  }
}

.card-header {
  width: 90%;
  background-color: #ffffff;
  color: #303133;
  margin: 0 auto;
  border-radius: 2px;
  padding: 15px;
}

.section-info {
  background-color: hsla(0, 0%, 100%, 0.2);
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #fff;
  padding: 15px;
  width: 100%;

  &.section-1 {
    padding: 60px 15px 15px;
    margin-top: -40px;
  }
}

.input-fund {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 5px 11px;
  color: #1f2126;

  .sbui-input {
    background: transparent;
    border: none;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;

    &:hover,
    &:focus,
    &:active {
      outline: 0;
      box-shadow: none;
    }
  }

  .btn-all {
    color: #00abff;
    font-size: 15px;
    background: transparent;
    padding: 5px;
    width: 120px;
  }
}

.text-right {
  text-align: right;
}

.list-bank {
  margin-top: 15px;
  padding: 0 15px;

  .item {
    padding: 10px 0;
    border-bottom: 1px solid #585e66;
    margin-bottom: 15px;

    .card-icon {
      flex: none;
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 16px;
      margin-right: 8px;
      color: #171c31;
      background: #455168;
      text-align: center;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      padding: 5px;
      box-sizing: border-box;
    }

    p {
      font-size: 14px;
      line-height: 14px;
    }

    .item-center {
      align-items: center;
    }
  }
}

.card-note {
  background-color: #f6f6f6;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 25px;
  margin-bottom: 5px;
}

.bank_footer {
  position: fixed;
  z-index: 999;
  left: 0;
  bottom: 0;
  height: 60px;
  width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 6px 1px #000;
  box-shadow: 0 0 6px 1px #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;

  .sbui-btn {
    display: block;
    width: 100%;
    text-align: center;
    background: #3340ff;
    color: #fff;
    line-height: 40px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    font-size: 16px;
  }
}

.bank-page {
  padding-bottom: 80px;
}

.bank-info {
  margin-bottom: 10px;
}

.form__cell {
  display: flex;
  align-items: center;
  height: 50px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e6e6e6;

  .form__label {
    flex: none;
    min-width: 8em;
    color: #000;
    font-size: 13px;
  }

  .form__field {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    flex: auto;

    .sbui-input {
      height: 40px;
      width: 100%;
      border: none;
      background: #f7f6fb;
    }
  }
}

.left-draw {
  position: fixed;
  left: 0;
  top: 0;
  width: 80%;
  height: 100vh;
  z-index: 99999;

  .content {
    position: relative;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 10px 0;
    overflow: hidden;
    overflow-y: auto;

    .title {
      color: #666;
      padding: 10px 15px;
      font-size: 18px;
      font-weight: bold;
    }

    .coin-list {
      box-sizing: border-box;
      padding: 0 10px;

      .search-container {
        width: 100%;
      }
    }
  }
}

.search-wrapper {
  padding: 10px 12px;

  .input-search {
    background: #f5f5f5;
    color: #535761;
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 25px;
    gap: 10px;
    overflow: hidden;

    svg {
      font-size: 16px;
    }

    input {
      font-size: 18px;
      width: 100%;
      background: transparent;

      &::placeholder {
        color: #535761;
        font-size: 16px;
      }

      &:focus {
        box-shadow: none;
        outline: 0;
        border: none;
      }
    }
  }
}

.btn-add-wallet {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  background: #588bf7 !important;
  width: 95%;
  border: none;
  -webkit-border-radius: 200px;
  border-radius: 200px;
  color: #fff !important;
  font-size: 16px;
  margin: 20px 0;
  height: 40px;
}

.sbui-sidepanel-container {
  &.register-page {
    background-color: #f7f6fb;

    .sbui-sidepanel {
      background: transparent;
    }

    .sbui-auth {
      padding: 5% 6%;
    }

    .tabs {
      background: transparent;

      .tab {
        font-size: 18px;
        color: #777;
        line-height: 23px;

        &.active {
          font-size: 23px;
          line-height: 28px;
          color: #0b1622;

          &::after {
            bottom: 0;
          }
        }
      }
    }

    .capcha {
      display: flex;
      align-items: center;

      img {
        height: 35px;
        width: auto;
      }
    }
  }
}

.feature-footer {
  position: fixed;
  z-index: 99999;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: #ffffff;
  padding: 5px 10px;

  .btn-feature {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 95%;
    color: #ffffff;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 16px;

    &.sell {
      background-color: #cd4e65;
    }

    &.buy {
      background-color: #1cad90;
    }
  }
}

// Update 08/27/2023
.no_headers {
  width: 100%;
  height: 60px;
}

.fd-box {
  width: 200px;
  height: 80px;
  line-height: 80px;
}

.dfs {
  color: rgb(0, 0, 0);
  margin: 2px 16px 2px 0px;
  padding: 0px 0px 5px 0px;
}

.fsl {
  border-bottom: 1px solid #fff;
  font-weight: bold;
}

.fsr {
  color: #636363;
}

.emptybox {
  width: 100%;
  background: #e2e2e2;
  height: 1px;
}

.dong_order_p {
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 10px;
  padding-left: 5px;
  color: #273546 !important;
  font-size: 14px !important;
}

.allmoneybox {
  width: 96%;
  height: 122px;
  background-size: 100% 200px;
  background: linear-gradient(180deg, #fbbc01, #fbbc01);
  color: #fff;
  margin: 0px 2%;
  border-radius: 10px;
  margin-top: 10px;

  .allmbox_1 {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: right;
    padding: 0px 15px;
  }

  .allmbox_2 {
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: left;
    padding: 0px 15px;
  }

  .allmbox_3 {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding: 0px 15px;
  }

  .allmbox_btn {
    width: 100%;
    height: 35px;
    line-height: 35px;
    padding: 0px 10px;

    .allmbox_btn_1 {
      width: 100%;
      height: 35px;
      float: left;
      margin-top: 10px;

      a {
        img {
          display: inline-block;
        }

        svg {
          font-size: 22px;
          color: #fbbc01;
        }

        .allmbox_cbtn {
          width: 30%;
          height: 35px;
          line-height: 35px;
          text-align: center;
          float: left;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
        }

        .allmbox_tbtn {
          width: 30%;
          height: 35px;
          line-height: 35px;
          text-align: center;
          float: left;
          border-radius: 5px;
          margin-left: 5%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.findbox_1 {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0px 15px;

  .findbox_2 {
    width: 60%;
    height: 40px;
    line-height: 40px;
    float: left;

    .findbox_3 {
      height: 40px;
      border-bottom: 2px solid #00b897;
      float: left;
      text-align: center;
    }
  }
}

.content_title {
  width: 100%;
  max-width: 500px;
  height: 53px;
  line-height: 53px;
  position: sticky;
  z-index: 999;
  top: 55px;
  padding: 0px 10px;
  background: #f2f3f5;
  color: #444;

  .active-tab {
    font-weight: 600;
    color: #000;
  }

  .content_title_l {
    width: 70%;
    height: 50px;
    line-height: 50px;
    float: left;

    img {
      display: inline-block;
    }
  }

  .content_title_r {
    width: 30%;
    height: 50px;
    line-height: 50px;
    float: right;
    padding: 0px 5px;

    svg {
      display: inline-block;
    }
  }
}

.formbox_op {
  height: 30px;

  .formbox_op_list {
    width: 80px;
    height: 30px;
    line-height: 30px;
    float: left;
    text-align: center;
  }
}

.o_title_box {
  width: 50%;
  height: 40px;
  line-height: 40px;
  background: #121420;
}

.listbox {
  width: 100%;
  background: #ffffff;
  padding: 5px 15px;
  margin-bottom: 5px;
  border-radius: 0;
  border-bottom: 1px solid #f5f5f5;

  .fbv {
    color: #888;
  }

  .listbox_title {
    width: 100%;
    height: 30px;
    line-height: 30px;

    .listbox_title_l {
      width: 50%;
      height: 30px;
      float: left;
      text-align: left;
    }

    .listbox_title_r {
      width: 50%;
      height: 30px;
      float: right;
      text-align: right;
    }
  }
}

.lbox {
  width: 100%;
  height: 50px;
  margin: 10px 0px;
  background: #f3f3f3;
  border-radius: 10px;
  padding: 0px 10px;

  .lbox_l {
    width: 50%;
    height: 50px;
    line-height: 50px;
    float: left;
    text-align: left;
  }

  .lbox_r {
    width: 50%;
    height: 50px;
    line-height: 50px;
    float: right;
    text-align: right;
  }
}

.topbox {
  width: 100%;
  max-width: 500px;
  height: 50px;
  padding: 5px 10px;
  position: fixed;
  top: 0px;
  background: #121420;
  z-index: 1;

  .topbox_1 {
    width: 80%;
    height: 40px;
    float: left;
    padding: 0px 10px;

    .topbox_1_b {
      width: 100%;
      height: 30px;
      margin-top: 5px;
      background: #f5f5f5;
      border-radius: 15px;
      padding: 0px 5px;

      .topbox_1_c {
        width: 20%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        float: left;
      }

      .topbox_1_d {
        width: 80%;
        height: 30px;
        line-height: 30px;
        float: right;

        .sybolc {
          width: 80%;
          height: 25px;
          line-height: 25px;
          margin-top: 2px;
          border: #f5f5f5;
          background: #f5f5f5;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .topbox_2 {
    width: 20%;
    height: 40px;
    line-height: 40px;
    float: left;
    text-align: center;
  }
}

.lmcoin {
  width: 100%;
  height: 44px;
  line-height: 44px;
  border-bottom: 1px solid #f7f7f7;
  font-size: 16px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coinlistbox_info_list {
  width: 100%;
  height: 60px;
  margin: 10px 0px;
  padding: 0 10px;
  background: #1b1d2a;
  border-radius: 10px;

  .coinlistbox_info_list_l {
    width: 30%;
    height: 60px;
    line-height: 60px;
    float: left;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .coinlistbox_info_list_c {
    width: 50%;
    height: 60px;
    line-height: 60px;
    float: left;
    text-align: center;
  }

  .coinlistbox_info_list_r {
    width: 20%;
    height: 60px;
    line-height: 60px;
    float: right;
    text-align: right;
  }
}

.contentbox_top {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: left;
}

.contentbox_line {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  padding: 0px 10px;

  .line_1 {
    width: 35%;
    height: 50px;
    line-height: 50px;
    float: left;
  }

  .line_2 {
    width: 65%;
    height: 50px;
    line-height: 50px;
    float: right;
    text-align: right;
  }
}

.textbox {
  width: 100%;
  background: #ffffff;
  margin-top: 10px;
  border-radius: 10px;
  padding: 15px;
}

.imgbox {
  width: 100%;
  padding: 10px;
  background: #ffffff;
  margin-top: 15px;
  border-radius: 10px;

  .imgbox_1 {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: left;

    span {
      font-size: 15px;
    }
  }

  .imgbox_2 {
    width: 100%;
    height: 35px;
    line-height: 35px;
    text-align: left;
  }

  .imgbox_3 {
    width: 100px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 5px;
    background: #e3e3e3;
    color: #888;
    cursor: pointer;
  }

  .showQr {
    background: #fbbc01;
    color: #ffffff;
  }

  .showQrb {
    border: 1px solid #fbbc01;
    color: #fbbc01;
  }

  .imgbox_4 {
    width: 100%;
    margin-top: 15px;
    border-top: 1px solid #f5f5f5;
    padding: 5px 0px;

    .imgbox_5 {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: left;
    }

    .zz_inputbox {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background: transparent;
      border-bottom: 1px solid #e7e7e7;
      border-radius: 0;
      padding: 0px;
      display: flex;
      align-items: center;

      .inputbox-select {
        color: #000;
      }

      .zz_input {
        width: 100%;
        height: 30px;
        line-height: 30px;
        background: transparent;
        border: none;
        padding: 0;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .imgbox_5 {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: left;
    }

    .imgbox_6 {
      width: 100%;
      margin-top: 10px;
      text-align: center;
    }

    .imgbox_7 {
      width: 100%;
      min-height: 30px;
      text-align: center;
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    .imgbox_8 {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;

      .imgbox_9 {
        width: auto;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #00b897;
        margin: auto;
        background: #00b897;
      }

      .imgbox_10 {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        margin: auto;
        background: linear-gradient(to right,
            rgb(33, 138, 220),
            rgb(85, 194, 255));
        color: #fff;
      }
    }
  }
}

.tx_inputbox {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border-bottom: 1px solid #edf0f5;
  border-radius: 0;
  padding: 0px;

  .tx_inputbox_l {
    width: 80%;
    height: 30px;
    margin-top: 5px;
    float: left;

    input {
      background: transparent;
      border: 0;
      font-size: 16px;

      &::placeholder {
        font-size: 16px;
        font-weight: 500;
        color: #c7c7c7;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .tx_inputbox_r {
    width: 20%;
    height: 30px;
    line-height: 30px;
    margin-top: 5px;
    float: right;
    text-align: right;

    .inputbox_r_l {
      width: 50%;
      height: 30px;
      line-height: 30px;
      float: left;
      text-align: center;
    }

    .inputbox_r_r {
      width: 50%;
      height: 30px;
      line-height: 30px;
      float: right;
      text-align: center;
    }
  }
}

.userbox {
  width: 100%;
  background: url(../img/banner/index.png) no-repeat;
  background-size: 100% 167px;
  padding: 40px 3% 25px 3%;
  color: #fff;
  height: 200px;

  .userbox_left {
    float: left;
    width: 30%;
    height: 100px;
    text-align: left;
    line-height: 100px;

    img {
      border-radius: 50%;
      width: 55%;
    }
  }

  .userbox_right {
    float: right;
    width: 70%;
    height: 100px;
  }
}

.wallet-qr {
  border: 1px solid #e3e3e3;
  padding: 8px;
  border-radius: 10px;
}

.no_inbox {
  width: 100%;
  margin-top: 0px;
  background: #ffffff;
  margin-top: 15px;
  min-height: 650px;

  .imgbox {
    width: 100%;
    height: 160px;
    margin-top: 60px;
    text-align: center;
    line-height: 160px;
    background: transparent;

    img {
      display: inline;
    }
  }

  .logobox {
    width: 100%;
    height: 30px;
    line-height: 30px;
    border: 1px solid #e3e3e3;

    .logobox_left {
      width: 30%;
      height: 30px;
      line-height: 30px;
      float: left;
      text-align: right;
    }

    .logobox_right {
      width: 65%;
      height: 30px;
      line-height: 30px;
      float: right;
      text-align: left;
    }
  }

  .txtbox {
    width: 96%;
    height: 80px;
    background: #ffffff;
    margin: 15px auto;
    border-radius: 15px;
    padding: 10px;
    margin-top: 30px;

    .txtbox_tb {
      width: 100%;
      height: 30px;
      line-height: 30px;
    }
  }

  .msg-box {
    background: #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    height: 120px !important;
  }
}

.inputbox {
  width: 100%;

  .input_title {
    width: 100%;
    height: 26px;
  }

  .input_div {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    padding: 0px 10px;
    background: #f7f7f7;

    .cinput {
      width: 100%;
      padding: 5px;
      height: 45px;
      background: transparent;
      border: none;
      height: 30px;
      font-size: 16px;
      outline: none !important;
      color: #000000;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.btnbox {
  width: 100%;
  max-width: 500px;
  height: 70px;
  background: #1b1d2a;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  text-align: center;

  .btnaddress {
    width: 92%;
    height: 40px;
    line-height: 40px;
    background: #00b897;
    color: #e6e6e6;
    margin: 0px auto;
    text-align: center;
    margin-top: 15px;
    border-radius: 10px;
  }
}

.addbox {
  width: 90%;
  height: 120px;
  background: #1b1d2a;
  margin-top: 20px;
  border-radius: 15px;
  margin: 10px auto;
  padding: 10px;

  .address {
    width: 100%;
    margin-top: 10px;
    word-wrap: break-word;
    word-break: normal;
  }
}

.select-language {
  background: transparent;
  padding: 0 6px;

  option {
    height: 40px;
  }
}

.index-images {
  display: flex;
  justify-content: flex-start;
  background: #191919;
  flex-wrap: wrap;
  width: 1300px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 20px 0 0 0;

  >div {
    width: 33.3%;
    padding: 20px 10px;

    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }
}

.why {
  background: #1f1f1f;
  text-align: center;
  padding: 70px 0;
  color: #fff;
}

.why-title {
  font-size: 34px;
  font-weight: 700;
  color: #fff;
}

.why-item {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.why-item li {
  margin: 0 10px;
  padding-top: 100px;

  img {
    margin: 0 auto;
  }
}

.why-image {
  width: 100px;
  height: 100px;
}

.why-item-title {
  font-size: 24px;
  font-weight: 700;
  padding: 40px 0 30px;
}

.why-item-desc {
  width: 370px;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  text-align: left;
  margin: 0 auto;
}

.why-item-desc:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: -18px;
  top: 7px;
}

.why-item li:nth-child(4),
.why-item li:nth-child(5) {
  width: 50%;
  margin: 0;
}

.why-item li:nth-child(4) .why-item-desc {
  width: 310px;
}

.why-item li:nth-child(5) .why-item-desc {
  width: 350px;
}

.news {
  background: #191919;
  text-align: center;
  padding: 70px 0;
}

.news-item {
  width: 1160px;
  margin: 0 auto;
  align-items: center;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
}

.news-item li {
  width: 32%;
  background: #1e1e1e;
  text-align: left;
  padding: 25px;
  box-sizing: border-box;
  border-radius: 10px;
}

.news-item li:before {
  content: "News";
  font-size: 10px;
  color: #000;
  width: 40px;
  height: 18px;
  background: #cfb47c;
  display: block;
  text-align: center;
  line-height: 18px;
  border-radius: 99px;
}

.news-title {
  height: 52px;
  line-height: 26px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin: 15px 0;
  -webkit-line-clamp: 2;
  color: #fff;
}

.news-desc,
.news-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.news-desc {
  height: 130px;
  font-size: 12px;
  line-height: 22px;
  color: #919899;
  margin-bottom: 10px;
  -webkit-line-clamp: 6;
}

.news-more {
  color: #1da2b4;
  font-size: 12px;
}

.guide {
  background: #1e1e1e;
  text-align: center;
  padding: 70px 0;
}

.guide-item {
  width: 1160px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
}

.guide-swiper {
  width: 706px;
  height: 479px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0;
}

.guide-swiper img {
  height: 479px;
}

.guide-text {
  text-align: left;
}

.guide-title {
  font-size: 18px;
  font-weight: 700;
  padding: 30px 0 20px;
  color: #fff;
}

.guide-desc {
  width: 357px;
  font-size: 14px;
  line-height: 34px;
  font-weight: 400;
  color: #fff;
}

.guide-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}

.guide-btn a {
  width: 171px;
  height: 60px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
  line-height: 60px;
  text-align: center;
}

.guide-btn a:first-child {
  border: 1px solid #fff;
  color: #fff;
}

.guide-btn a:nth-child(2) {
  border: 1px solid #1da2b4;
  background: #1da2b4;
  color: #fff;
}

.trade {
  background: #191919;
  text-align: center;
  padding: 70px 0 0;
  position: relative;
}

.trade-title {
  font-size: 34px;
  font-weight: 700;
  color: #fff;
}

.trade-desc {
  font-size: 16px;
  padding: 15px 0;
  color: #fff;
}

.trade-item-on {
  font-size: 18px;
  color: #b3c3c6;
  font-weight: 700;
  padding: 15px 0;
}

.trade-item {
  width: 1160px;
  height: 460px;
  margin: 0 auto;
  text-align: left;
  position: relative;
  z-index: 3;
}

.trade-item-title {
  font-size: 18px;
  font-weight: 700;
  padding: 80px 0 10px;
  color: #fff;
}

.trade-item-desc {
  width: 440px;
  font-size: 14px;
  color: #919899;
  line-height: 24px;
}

.trade-image {
  width: 100%;
  padding-top: 50px;
}

.trade-image img {
  width: 100%;
}

.trade-down {
  width: 430px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.trade-down a {
  position: relative;
  margin: 10px 0;
}

.trade-down img {
  border-radius: 8px;
}

.trade-down p {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 46px;
  text-indent: 50px;
  font-size: 14px;
}

.fedui-footer {
  width: 100%;
  background-color: #191919;
  color: #697080;
  padding-top: 60px;
  padding-bottom: 0;
  min-width: 1280px;
}

.fedui-root,
.fedui-root *,
.fedui-root :after,
.fedui-root :before {
  box-sizing: border-box;
}

.fedui-footer-inner {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.fedui-footer-logo {
  height: 23px;
}

.fedui-footer-copyright {
  font-size: 12px;
  color: #6a707f;
  line-height: 12px;
  padding-top: 16px;
}

.fedui-footer-link {
  padding-top: 40px;
}

.fedui-footer-link a:first-child {
  margin-left: 0;
}

.fedui-footer-link a {
  margin-left: 8px;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  color: #a8b7c8;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
}

.fedui-footer-right {
  width: 792px;
  display: flex;
  justify-content: space-between;
}

.fedui-footer-right a:hover {
  color: #fff;
}

.fedui-footer-right dl {
  width: 180px;
}

.fedui-root dd,
.fedui-root dl,
.fedui-root dt,
.fedui-root li,
.fedui-root ul {
  padding: 0;
  margin: 0;
}

.fedui-footer-right dt {
  line-height: 20px;
  font-size: 16px;
  padding-bottom: 8px;
}

.fedui-footer[data-locale="ko-kr"] .fedui-footer-right a,
.fedui-footer[data-locale="zh-cn"] .fedui-footer-right a,
.fedui-footer[data-locale="zh-hk"] .fedui-footer-right a,
.fedui-footer[data-locale="zh-tw"] .fedui-footer-right a {
  line-height: 18px;
}

.fedui-footer-right a {
  padding-top: 8px;
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: #d5def2;
  word-wrap: break-word;
}

.mu-list {
  padding: 8px 0;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
  margin: 0;
}

.mu-list,
.mu-list>li {
  display: block;
}

.mu-list .mu-sub-header:first-child {
  margin-top: -8px;
}

.mu-list .mu-list {
  padding: 0;
}

.mu-item-wrapper {
  display: block;
  color: inherit;
  position: relative;
  outline: none;
  cursor: pointer;
}

.mu-item-wrapper.hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.mu-item-wrapper.disabled {
  cursor: default;
}

.mu-list-dense .mu-item {
  height: 36px;
}

.mu-list-dense .mu-icon {
  font-size: 22px;
}

.mu-list-dense .mu-item-title {
  font-size: 14px;
}

.mu-item {
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.87);
}

.mu-item.has-avatar {
  height: 56px;
}

.mu-list-two-line .mu-item {
  height: 72px;
}

.mu-list-three-line .mu-item {
  height: 88px;
}

.mu-item.is-selected {
  color: #2196f3;
}

.mu-item.mu-icon-left {
  margin-right: 16px;
}

.mu-item-action {
  min-width: 56px;
  display: flex;
  height: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
}

.mu-item-action:first-child .mu-icon-button {
  margin-left: -12px;
}

.mu-item-action:last-child .mu-icon-button {
  margin-right: -12px;
}

.mu-item-action.is-more {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mu-list-three-line .mu-item-action .mu-avatar {
  margin-top: -18px;
}

.mu-item-content,
.mu-item-title {
  flex: 1 1 auto;
  text-align: left;
  min-width: 1px;
}

.mu-item-content+.mu-item-action:not(.is-more),
.mu-item-title+.mu-item-action:not(.is-more) {
  justify-content: flex-end;
}

.mu-item-title {
  font-size: 16px;
  height: 24px;
  line-height: 24px;
}

.mu-item-sub-title,
.mu-item-title {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.mu-item-sub-title {
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.54);
}

.mu-list.mu-list-three-line .mu-item-sub-title {
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.mu-item-after-text {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.mu-avatar {
  display: inline-block;
  height: 40px;
  width: 40px;
  font-size: 20px;
  color: #fff;
  background-color: #bdbdbd;
  text-align: center;
  border-radius: 50%;
}

.mu-avatar img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.mu-avatar-inner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.mu-badge-container {
  display: inline-block;
  position: relative;
}

.mu-badge {
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  line-height: 1.5;
  font-size: 12px;
  font-style: normal;
  background-color: #bdbdbd;
  color: #fff;
  border-radius: 3px;
  overflow: hidden;
}

.mu-badge-float {
  position: absolute;
  top: -12px;
  right: -12px;
}

.mu-badge-circle {
  border-radius: 50%;
  padding: 0;
  width: 24px;
  height: 24px;
  overflow: hidden;
}

.mu-bottom-nav {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  text-align: center;
  outline: none;
  position: relative;
  color: rgba(0, 0, 0, 0.54);
}

.mu-bottom-nav-shift {
  background-color: #2196f3;
  color: hsla(0, 0%, 100%, 0.7);
}

.mu-bottom-nav-shift-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mu-bottom-item {
  flex: 1;
  min-width: 80px;
  max-width: 168px;
  position: relative;
  height: 100%;
  padding: 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  border: none;
  outline: none;
  transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 6px;
  cursor: pointer;
  color: inherit;
}

.mu-bottom-nav-shift .mu-bottom-item {
  padding: 8px 12px 10px;
  min-width: 56px;
  max-width: 96px;
}

.mu-bottom-item-wrapper {
  display: block;
  height: 100%;
}

.mu-bottom-item-active {
  padding-top: 6px;
  padding-bottom: 5px;
  color: #2196f3;
}

.mu-bottom-item-active.is-shift {
  color: #fff;
}

.mu-bottom-item-active .mu-bottom-item-text {
  font-size: 14px;
}

.mu-bottom-nav-shift .mu-bottom-item-active {
  flex: 1.7;
  min-width: 96px;
  max-width: 168px;
  padding-top: 6px;
  padding-bottom: 5px;
}

.mu-bottom-item-text {
  display: block;
  text-align: center;
  font-size: 12px;
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1),
    font-size 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mu-bottom-nav-shift .mu-bottom-item-text {
  opacity: 0;
  transform: scale(1) translate3d(0, 6px, 0);
}

.mu-bottom-nav-shift .mu-bottom-item-active .mu-bottom-item-text {
  transform: scale(1) translate3d(0, 2px, 0);
  opacity: 1;
}

.mu-bottom-item-icon {
  display: block;
  margin: auto;
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 24px;
}

.mu-bottom-nav-shift .mu-bottom-item-icon {
  transform: translate3d(0, 8px, 0);
}

.mu-bottom-nav-shift .mu-bottom-item-active .mu-bottom-item-icon {
  transform: scale(1) translateZ(0);
}

.mu-primary-color {
  background-color: #fbbc01;
}

.mu-warning-color {
  background-color: rgb(255, 193, 7);
}

.mu-ripple-wrapper {
  overflow: hidden;
}

.mu-circle-ripple,
.mu-ripple-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mu-circle-ripple {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 50%;
  background-color: currentColor;
  background-clip: padding-box;
  opacity: 0.1;
}

.mu-ripple-enter-active,
.mu-ripple-leave-active {
  transition: opacity 2s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
}

.mu-ripple-enter {
  transform: scale(0);
}

.mu-ripple-leave-active {
  opacity: 0 !important;
}

.mu-icon-button {
  line-height: 1;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 24px;
  padding: 12px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  color: inherit;
  background-color: transparent;
}

.input-item {
  display: flex;
  align-items: center;
  gap: 5px;

  .label {
    width: 20%;
    font-size: 14px;
    color: #637085;
  }

  .input-group {
    background: #191b1c;
    border: 1px solid #323232;
    border-radius: 4px;
    height: 43px;
    display: flex;
    align-items: center;
    width: 100%;

    span {
      color: #bdbdbd;
      font-size: 12px;
      padding: 0 6px;
    }

    input {
      background: transparent;
      border: none;
      box-shadow: none;
      outline: 0;
      width: 100%;
      height: 43px;
      font-size: 14px;
      line-height: 43px;
      color: #f6f6f6;
      font-weight: 500;
    }
  }
}

.boximg {
  height: 230px;
  width: 100%;
  background: #000 url(../img/bg-fund.png) no-repeat top / contain;
}

.boxtitle article {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 50px;
  position: relative;
}

.boxtitle span:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 18px;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent transparent #ffd12d;
}

.boxtitle span {
  position: relative;
  background: #ffd12d;
  color: #000;
  font-weight: 700;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 14px;
}

.boxtitle h2 {
  font-weight: 700;
  text-align: center;
  font-size: 30px;
  color: #fff;
  margin-top: 20px;
}

.input-profit {
  input {
    background-color: #f6f6f6;
    height: 40px;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    outline: 0;
    width: 50%;

    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}

.wallet-page {
  padding-bottom: 80px;
  background: #f8f8f8;
  min-height: calc(100% - 60px);

  .tabs {
    overflow: hidden;
    overflow-x: auto;
  }

  .list-token {
    background: #fff;
    height: 100%;
    border-radius: 20px 20px 0 0;
    min-height: calc(100vh - 340px);
  }
}

.btn-contract {
  background-color: #edf0f5;
  color: #4c4f53;
  font-size: 12px;
}

.deposit-tip {
  ul {
    padding-left: 15px;

    li {
      padding-left: 10px;
      list-style: disc;
      margin-bottom: 10px;
    }
  }
}

.mode-style {
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  display: flex;
  align-items: center;

  .poin {
    width: 8px;
    height: 8px;
    background: #bdbdbd;
    border-radius: 50%;
  }

  .group {
    width: calc(100% - 80px);

    .item {
      height: 53px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #bdbdbd;
      padding: 0 15px;

      &:first-child {
        border-bottom: 1px solid #f6f6f6;
      }
    }
  }

  .swap {
    width: 80px;
    height: 100%;
    min-height: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;

    img {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background: #fff;
    }
  }
}

.zz-select {
  background: #f6f6f6;
  height: 45px;
  width: 100%;
  padding: 6px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  select {
    background: transparent;
    width: 100px;
    height: 45px;
    font-weight: 600;
    font-size: 16px;
  }

  span {
    color: #bdbdbd;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.zz_inputbox {
  width: 100%;
  font-size: 15px;
  border-bottom: 1px solid #eeeeee;

  .zz_input {
    border: none;
    width: calc(100% - 80px);
    font-size: 18px;
    font-weight: 600;

    &:focus {
      background: transparent;
      border: none;
      outline: 0;
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 600;
      font-size: 18px;
      color: #c8c9cc;
    }
  }
}

.curent-time {
  width: 100%;
  background: rgba(46, 150, 246, 0.1);
  border-radius: 76px;
  padding: 6px;
  text-align: center;
}

.table-fund {
  color: #606266;
  border: 1px solid #ebebeb;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: 12px 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  th {
    font-size: 18px;
    color: #989898;
  }

  tr {
    border-bottom: 1px solid #ebebeb;
  }

  .btn-mine {
    border-color: transparent;
    color: #409eff;
    background: 0 0;
    padding-left: 0;
    padding-right: 0;
    min-height: 30px;
  }
}

.login-bg {
  background: #fbbc01;
  height: 144px;
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  padding: 30px 15px;

  .login-box {
    width: 200px;
    height: 36px;
    margin: 10px auto;
    border-radius: 20px;
    border: 1px solid #fff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .register {
      width: 50%;
      height: 36px;
    }

    .login {
      width: 50%;
      height: 36px;
      background: #ffffff;
      color: #fbbc01;
      border-radius: 20px;
    }
  }
}

.user-page {
  margin-top: 60px;
  color: #2c3e50;
  background-color: #f8f8f8;
  width: 100%;
  min-height: 100%;
  padding: 30px 0 60px 0;
  box-sizing: border-box;
}

.box-sm {
  width: 1120px;
  min-height: 710px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 2px 2px 10px #f8f8f8;
}

.person-left {
  border-right: 1px solid;
  border-color: #eaecef;
  width: 140px;
  min-height: 710px;
}

.slidebar {
  width: 100%;
  padding: 30px 15px;
  box-sizing: border-box;

  .title {
    color: #c1c1c1;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }

  .namebox {
    margin-bottom: 30px;
    padding-inline-start: 25px;
    padding-top: 16px;
    padding-left: 10px;

    .router-link {
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      word-break: break-word;
      display: block;
      line-height: 1.2;
      padding: 7px 8px;
      color: #424344;
      cursor: pointer;

      &.active {
        background: rgba(17, 193, 164, 0.1);
        border-radius: 4px;
      }
    }
  }
}

.person-right {
  width: calc(100% - 140px);

  .title {
    width: 100%;
    height: 65px;
    padding: 20px 30px;
    font-weight: 700;
    margin-bottom: 1px;
    background-color: #fff;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    border-bottom: 1px solid #eaecef;
  }

  .spot-account {
    background: #fff;

    .search-container {
      position: relative;
      border-radius: 4px;
      background: #fff;
      border: 1px solid #dcdfe6;
      color: #606266;
      display: inline-block;
      height: 40px;
      line-height: 40px;

      svg {
        position: absolute;
        left: 15px;
        top: 10px;
        font-size: 18px;
        color: #606266;
      }

      input {
        height: 40px;
        width: 100%;
        padding: 6px 11px 6px 30px;
      }
    }
  }
}

.btn-asset {
  padding: 2px 4px;
  height: 22px;
  line-height: 22px;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
}

.change {
  border: 1px solid #fbc501;
  color: #fbc501;
}

.primary {
  color: #fff;
  background: #fbc501;
}

.header-info {
  width: 100%;
  height: 224px;
  padding: 30px;
  box-sizing: border-box;
  background: #fff;
  background-image: url(../img/bg-account.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

.current-num {
  background: #fff;
  width: 100%;
  height: 126px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.huazhuan {
  min-width: 148px;
  height: 42px;
  line-height: 42px;
  padding: 0 21px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  margin-inline-start: 10px;
  background-color: #fbbc01;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}

.safety {
  min-height: 500px;
  background: #fff;

  .borderBase {
    padding: 20px 20px 20px 30px;
    box-sizing: border-box;
    text-align: start;
    border-bottom: 1px solid #eaecef;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.tabs {
  &.tab-box {
    height: 48px;
    padding: 0 30px;
    border: none;
    border-bottom: 1px solid #eaecef;

    .tab {
      color: #989898;
      border: none;
      height: 100%;
      line-height: 48px;
      margin-inline-end: 30px;
      font-size: 14px;
      position: relative;
      white-space: nowrap;
      flex: initial;
      font-weight: 500;
      cursor: pointer;

      &.active {
        background: transparent;
        color: #424344;
        font-weight: 600;
        border-bottom: 4px solid #fbbc01;
      }
    }
  }
}

.link {
  color: #2e96f6;
  font-weight: 500;
  cursor: pointer;
}

.table-coin {
  width: 100%;
  font-size: 14px;
  border-collapse: separate;
  text-indent: initial;
  background: #fff;

  td,
  th {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    text-align: left;
    color: #606266;
    border-bottom: 1px solid #ebeef5;
  }

  .cell {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 23px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: start;
  }

  thead {
    color: #909399;
    font-weight: 500;

    th {
      background: #f8f8f8;
      color: #989898;
      text-align: start;
    }
  }
}

.recharge-record {
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  background: #fff;
}

.desktop-layout {
  .sbui-select {
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 600;
  }

  .sbui-input {
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 600;
  }
}

.detail-box {
  padding: 20px 30px 30px;
  width: 100%;
  background: #fff;
}

.lever-box {
  width: 130px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.lever {
  width: 42px;
  height: 42px;
  background: linear-gradient(254.63deg, #fcffeb, #ffda91 95.19%);
  box-sizing: border-box;
  font-family: DINPro;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #d7ac58;
  padding: 9px 10px;
  border-radius: 50%;
  margin-bottom: 12px;
  text-align: center;
}

.current-lever {
  font-weight: 700;
  line-height: 18px;
  color: #fbbc01;
  margin-inline-start: 12px;
}

.progress-box {
  padding-top: 20px;
  padding-inline-start: 30px;
  padding-inline-end: 30px;
  margin-inline-end: 37px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #121212;
}

.el-progress {
  position: relative;
  line-height: 1;
  display: flex;
  align-items: center;

  .el-progress-bar {
    flex-grow: 1;
    box-sizing: border-box;

    .el-progress-bar__outer {
      height: 10px;
      border-radius: 100px;
      background-color: #ebeef5;
      overflow: hidden;
      position: relative;
      vertical-align: middle;
    }
  }

  .el-progress__text {
    font-size: 16px;
    color: #606266;
    margin-left: 5px;
    min-width: 50px;
    line-height: 1;

    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #bdbdbd;
    }

    .progress-text {
      color: #bdbdbd;
      min-width: 120px;
      display: inline-block;
    }
  }
}

.rebate {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #989898;
  margin-bottom: 16px;
}

.rebate-num {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #424344;
}

.kerword {
  margin-top: 6px;
  width: 186px;
  height: 42px;
  background: #f8f8f8;
  padding: 0 12px;
  box-sizing: border-box;
  line-height: 42px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quote-page {
  background: #f8f8f8;
  padding: 20px 0 60px 0;

  .quote-box {
    width: 1120px;
    margin: 0 auto;
    background: #fff;
  }
}

.bd-link {
  border: 1px solid #fbbc01;
  color: #fbbc01;
  padding: 0 10px;
  font-size: 13px;
  border-radius: 20px;
  font-weight: 500;
}

.split-line-10 {
  width: 100%;
  height: 12px;
  background-color: #f8f8f8;
}