@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./begin.scss";
@import "./custom.scss";
@import "./main.scss";
@import "./base";
@import "./page";
@import "./login";
